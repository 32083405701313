import { FormikProps } from 'formik'
import { UserOrganizationalFlowInfoNode } from 'modules/organization/diagram/domain/UserOrganizationalFlowInfoNode'
import { useUserActiveDirectoryInfoFindQuery } from 'modules/organization/user/info/application/find/UserOrganizationalInfoFindQueries'
import { UserActiveDirectoryInfo } from 'modules/organization/user/info/domain/UserActiveDirectoryInfo'
import { UserOrganizationalInfoRepository } from 'modules/organization/user/info/domain/repository/UserOrganizationalInfoRepository'
import { useUserOrganizationalTypeFindAllQuery } from 'modules/organization/user/type/application/find/UserOrganizationalTypeFindQueries'
import { UserOrganizationalTypeFindResponse } from 'modules/organization/user/type/application/find/dto/UserOrganizationalTypeFindResponse'
import { UserOrganizationalType } from 'modules/organization/user/type/domain/UserOrganizationalType'
import { UserOrganizationalTypeRepository } from 'modules/organization/user/type/domain/repository/UserOrganizationalTypeRepository'
import { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { IUfinetSelectOption } from 'ufinet-web-components'
import { IdAndValue, useTranslator } from 'ufinet-web-functions'
import { UserOrganizationFormParameters, useUserOrganizationFormValidation } from './useUserOrganizationFormValidation'

export type UserOrganizationFormData = {
	userType: number
	userEmail: string
}

type HookInput = {
	userOrganizationalTypeRepository: UserOrganizationalTypeRepository
	userOrganizationalInfoRepository: UserOrganizationalInfoRepository
	onUserAdded: (userOrganizationalFlowInfoNode: UserOrganizationalFlowInfoNode) => void
}

type HookOutput = {
	user: {
		type: {
			loading: boolean
			value?: IdAndValue<number, UserOrganizationalType>
			options: IUfinetSelectOption[]
			selectedOption?: IUfinetSelectOption
		}
		email: string
		activeDirectoryInfo?: UserActiveDirectoryInfo
	}
	onChange: {
		userType: (userType?: IUfinetSelectOption) => void
		userEmail: (userEmail: string) => void
	}
	form: {
		formik: FormikProps<UserOrganizationFormParameters>
		complete: boolean
		loading: boolean
	}
}

function useUserOrganizationForm({
	userOrganizationalTypeRepository,
	userOrganizationalInfoRepository,
	onUserAdded,
}: HookInput): HookOutput {
	const translate = useTranslator()

	const [userTypeOptions, setUserTypeOptions] = useState<IUfinetSelectOption[]>([])
	const [userType, setUserType] = useState<IdAndValue<number, UserOrganizationalType>>()
	const userTypeOption = useMemo(
		() => userTypeOptions.find((opt) => opt.value === userType?.id.toString()),
		[userType, userTypeOptions]
	)
	const [userEmail, setUserEmail] = useState<string>('')

	const { formik } = useUserOrganizationFormValidation({
		onSubmit: async () => {
			await fetchUserActiveDirectoryInfo()
		},
		onReset: () => {
			setUserType(undefined)
			setUserEmail('')
		},
	})

	const onUserTypeChanged = (userTypeOption?: IUfinetSelectOption) => {
		if (!userTypeOption || !userTypeOption.name) {
			setUserType(undefined)
			formik.setValues({ ...formik.values, userType: undefined })
			return
		}
		const selectedUserTypeData: IdAndValue<number, UserOrganizationalType> = {
			id: parseInt(userTypeOption.value),
			value: userTypeOption.name as UserOrganizationalType,
		}
		setUserType(selectedUserTypeData)
		formik.setValues({ ...formik.values, userType: selectedUserTypeData.id })
		formik.setErrors({ ...formik.errors, userType: undefined })
	}

	const onUserEmailChanged = (userEmail: string) => {
		const newUserEmail = userEmail.trim()
		setUserEmail(newUserEmail)
		formik.setValues({ ...formik.values, userEmail: newUserEmail })
	}

	const onUserOrganizationalTypesFetched = (userTypes: UserOrganizationalTypeFindResponse[]): void => {
		const newUserTypeOptions: IUfinetSelectOption[] = userTypes.map((userType) => ({
			value: userType.id.toString(),
			name: userType.value,
			label: translate(`USER.ORGANIZATIONAL.TYPE.${userType.value}`),
		}))
		setUserTypeOptions(newUserTypeOptions)
	}

	const { data: userOrganizationalTypes, isLoading: userOrganizationalTypesLoading } =
		useUserOrganizationalTypeFindAllQuery(userOrganizationalTypeRepository)

	useEffect(() => {
		onUserOrganizationalTypesFetched(userOrganizationalTypes || [])
		return () => {
			onUserOrganizationalTypesFetched([])
		}
	}, [userOrganizationalTypes])

	const {
		data: userActiveDirectoryInfo,
		isInitialLoading: _userActiveDirectoryInfoLoading,
		refetch: fetchUserActiveDirectoryInfo,
	} = useUserActiveDirectoryInfoFindQuery(
		userOrganizationalInfoRepository,
		{ email: userEmail },
		{
			onError: (err) => {
				switch (err.statusCode) {
					case 404:
						toast.error(translate('USERS.ORGANIZATION.FORM.SUBMIT.ERROR.NOT_FOUND'))
						break
					default:
						toast.error(translate('USERS.ORGANIZATION.FORM.SUBMIT.ERROR.GENERIC'))
				}
			},
		}
	)

	useEffect(() => {
		if (userActiveDirectoryInfo) {
			formik.resetForm()

			if (!userType) return

			onUserAdded({ name: userActiveDirectoryInfo.fullName, email: userEmail, userHierarchyType: userType.value })
		}
	}, [formik, onUserAdded, userActiveDirectoryInfo, userEmail, userType])

	return {
		user: {
			type: {
				loading: userOrganizationalTypesLoading,
				value: userType,
				options: userTypeOptions,
				selectedOption: userTypeOption,
			},
			email: userEmail,
			activeDirectoryInfo: userActiveDirectoryInfo,
		},
		onChange: {
			userType: onUserTypeChanged,
			userEmail: onUserEmailChanged,
		},
		form: {
			formik,
			complete: !!formik.values.userEmail && !!formik.values.userType,
			loading: formik.isSubmitting,
		},
	}
}

export { useUserOrganizationForm }
