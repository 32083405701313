import { RoleTypes } from 'modules/role/domain/types/RoleTypes'
import { IdAndName, UserTypesEnum } from 'ufinet-web-functions'

export type InternalUser = {
	userType: UserTypesEnum
	email: string
	roles: (IdAndName<number> & { type?: RoleTypes })[]
}

export const initialInternalUser: InternalUser = {
	userType: UserTypesEnum.INTERNAL_USER,
	email: '',
	roles: [],
}
