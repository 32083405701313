import { RolePermissionsByApplication } from './permission/RolePermissionsByApplication'
import { RoleTypes } from './types/RoleTypes'

export type Role = {
	id?: number
	name: string
	type: RoleTypes
	creationBy: string
	creationAt: string
	lastModifiedBy: string
	lastModifiedAt: string
}

export type RoleDetail = Role & {
	permissions: RolePermissionsByApplication[]
}

export const emptyRole: Role = {
	name: '',
	type: RoleTypes.UNDEFINED,
	creationBy: '',
	creationAt: '',
	lastModifiedBy: '',
	lastModifiedAt: '',
}

export const emptyRoleDetail: RoleDetail = {
	name: '',
	type: RoleTypes.UNDEFINED,
	creationBy: '',
	creationAt: '',
	lastModifiedBy: '',
	lastModifiedAt: '',
	permissions: [],
}
