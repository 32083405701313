import { Role } from 'modules/role/domain/Role'
import { ColData, DivWrapped } from 'ufinet-web-components'
import { convertToLocalTZ } from 'ufinet-web-functions'

export const rolesColsData: ColData[] = [
	{ field: 'name', header: 'COL.NAME', width: '250px' },
	{ field: 'type', header: 'COL.ROL_TYPE', width: '250px' },
	{
		field: 'creationBy',
		header: 'COL.CREATE_BY',
		width: '250px',
		nonFilterable: true,
		body: (row: Role) => <DivWrapped className="ufinet-table-cell" input={row.creationBy} separator="@" />,
	},
	{
		field: 'creationAt',
		header: 'COL.CREATE_AT',
		width: '220px',
		nonFilterable: true,
		body: (row: Role) => convertToLocalTZ(row.creationAt),
	},
	{
		field: 'lastModifiedBy',
		header: 'COL.ACT_BY',
		width: '250px',
		nonFilterable: true,
		body: (row: Role) => <DivWrapped className="ufinet-table-cell" input={row.lastModifiedBy} separator="@" />,
	},
	{
		field: 'lastModifiedAt',
		header: 'COL.ACT_AT',
		width: '220px',
		nonFilterable: true,
		body: (row: Role) => convertToLocalTZ(row.lastModifiedAt),
	},
]
