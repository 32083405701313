import { faArrowDownShortWide, faSave, faUpload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UserOrganizationalFlowFindResponse } from 'modules/organization/diagram/application/find/dto/UserOrganizationalFlowFindResponse'
import { UserOrganizationalFlowInfoNode } from 'modules/organization/diagram/domain/UserOrganizationalFlowInfoNode'
import { UserOrganizationalFlowRepository } from 'modules/organization/diagram/domain/repository/UserOrganizationalFlowRepository'
import { InputText } from 'primereact/inputtext'
import { FC, useMemo } from 'react'
import ReactFlow, { Background, ConnectionLineType, ControlButton, Controls } from 'reactflow'
import { Loading, UfinetButton } from 'ufinet-web-components'
import { useTranslator } from 'ufinet-web-functions'
import { UfinetNode } from './UfinetNode'
import { useUserOrganizationFlow } from './hooks/useUserOrganizationFlow'
import { Direction } from './types/Directions'

type Props = {
	hierarchy: UserOrganizationalFlowFindResponse
	newUser?: UserOrganizationalFlowInfoNode
	repository: UserOrganizationalFlowRepository
}

const UserOrganizationFlow: FC<Props> = ({ hierarchy, newUser, repository }: Props) => {
	const translate = useTranslator()
	const nodeTypes = useMemo(() => ({ ufinetNode: UfinetNode }), [])
	const {
		nodes,
		edges,
		onNodesChange,
		// onNodesDelete,
		onEdgesChange,
		onConnect,
		onEdgesDelete,
		onSave,
		onCharge,
		onAutoLayout,
		onSearchUsers,
		onSaveHerarchy,
		setReactFlowInstance,
		usersOrganizationalFlowLoading,
	} = useUserOrganizationFlow({ hierarchy, newUser, repository })

	return (
		<div className="flow-container">
			{usersOrganizationalFlowLoading && <Loading />}
			<div className="d-flex justify-content-between">
				<div>
					<span className="p-input-icon-left">
						<i className="pi pi-search" />
						<InputText
							onChange={(event) => onSearchUsers(event.target.value)}
							placeholder={translate('USERS.ORGANIZATION.HIERARCHY.SEARCH_IN_HIERARCHY')}
						/>
					</span>
				</div>
				<div>
					<UfinetButton
						icon={faSave}
						onClick={onSaveHerarchy}
						content={translate('USERS.ORGANIZATION.HIERARCHY.SAVE_HIERARCHY')}
					/>
				</div>
			</div>

			<ReactFlow
				nodes={nodes}
				edges={edges}
				onNodesChange={onNodesChange}
				// onNodesDelete={onNodesDelete}
				onEdgesChange={onEdgesChange}
				onConnect={onConnect}
				onEdgesDelete={onEdgesDelete}
				nodeTypes={nodeTypes}
				connectionLineType={ConnectionLineType.Bezier}
				fitView
				proOptions={{ hideAttribution: true }}
				onInit={setReactFlowInstance}
				className="react-flow my-3"
			>
				<Background />
				<Controls showZoom={false}>
					<ControlButton onClick={onSave}>
						<FontAwesomeIcon icon={faSave} />
					</ControlButton>
					<ControlButton onClick={onCharge}>
						<FontAwesomeIcon icon={faUpload} />
					</ControlButton>
					<ControlButton onClick={() => onAutoLayout(Direction.TopToBottom)}>
						<FontAwesomeIcon icon={faArrowDownShortWide} />
					</ControlButton>
					<ControlButton onClick={() => onAutoLayout(Direction.LeftToRight)}>
						<FontAwesomeIcon icon={faArrowDownShortWide} className="fa-rotate-270" />
					</ControlButton>
				</Controls>
			</ReactFlow>
		</div>
	)
}

export { UserOrganizationFlow }
