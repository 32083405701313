import dagre from 'dagre'
import { UserOrganizationalFlowFindResponse } from 'modules/organization/diagram/application/find/dto/UserOrganizationalFlowFindResponse'
import { useUserOrganizationalFlowUpdateQuery } from 'modules/organization/diagram/application/update/UserOrganizationalFlowUpdateQuery'
import { UserOrganizationalFlowInfoEdge } from 'modules/organization/diagram/domain/UserOrganizationalFlowInfoEdge'
import { UserOrganizationalFlowInfoNode } from 'modules/organization/diagram/domain/UserOrganizationalFlowInfoNode'
import { UserOrganizationalFlowRepository } from 'modules/organization/diagram/domain/repository/UserOrganizationalFlowRepository'
import { UserOrganizationalType } from 'modules/organization/user/type/domain/UserOrganizationalType'
import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import {
	Connection,
	Edge,
	EdgeChange,
	Node,
	NodeChange,
	Position,
	ReactFlowInstance,
	addEdge,
	applyNodeChanges,
	getConnectedEdges,
	getIncomers,
	getOutgoers,
	useEdgesState,
	useNodesInitialized,
	useNodesState,
	useReactFlow,
} from 'reactflow'
import { useTranslator } from 'ufinet-web-functions'
import { Direction } from '../types/Directions'
import { NodeState } from '../types/NodeState'

type OnChange<ChangesType> = (changes: ChangesType[]) => void

type HookInput = {
	hierarchy: UserOrganizationalFlowFindResponse
	newUser?: UserOrganizationalFlowInfoNode
	repository: UserOrganizationalFlowRepository
}

type HookOutput = {
	nodes: Node<UserOrganizationalFlowInfoNode>[]
	edges: Edge<Edge>[]
	onNodesChange: (changes: NodeChange[]) => void
	onEdgesChange: OnChange<EdgeChange>
	onNodesDelete: (deleted: Node[]) => void
	onConnect: (params: Connection) => void
	onEdgesDelete: (deleted: Edge[]) => void
	onSave: () => void
	onCharge: () => void
	onAutoLayout: (direction: string) => void
	onSearchUsers: (name: string) => void
	onSaveHerarchy: () => void
	setReactFlowInstance: (reactFlowInstance: ReactFlowInstance) => void
	usersOrganizationalFlowLoading: boolean
}

const flowKey = 'ufinet-flow'

const nodeGapWidth = 240
const nodeGapHeight = 80

function useUserOrganizationFlow({ hierarchy, newUser, repository }: HookInput): HookOutput {
	const translate = useTranslator()

	const dagreGraph = new dagre.graphlib.Graph()
	dagreGraph.setDefaultEdgeLabel(() => ({}))

	const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance>()
	const { setViewport } = useReactFlow()
	const nodesInitialized = useNodesInitialized()

	const [newUsers, setNewUsers] = useState<UserOrganizationalFlowInfoNode[]>([])
	const [deletedEdges, setDeletedEdges] = useState<Edge[]>([])
	const [addedEdges, setAddedEdges] = useState<Edge[]>([])

	const showSuccessAndClearArrays = () => {
		toast.success(translate('USERS.ORGANIZATION.HIERARCHY.SAVE_HIERARCHY.SUCCESS'))

		setDeletedEdges([])
		setAddedEdges([])
		setNewUsers([])
	}

	const localStorageDiagram = useCallback(() => {
		const flowStorage = localStorage.getItem(flowKey)
		if (!flowStorage) return

		return JSON.parse(flowStorage)
	}, [])

	const { isLoading: usersOrganizationalFlowLoading, mutateAsync: updateOrganizationFlow } =
		useUserOrganizationalFlowUpdateQuery(
			repository,
			{
				internalUsersToAdd: newUsers,
				relationsToAdd: addedEdges.map((edge) => ({
					source: edge.source,
					target: edge.target,
				})),
				relationsToDelete: deletedEdges.map((edge) => ({
					source: edge.source,
					target: edge.target,
				})),
			},
			{
				onSuccess: showSuccessAndClearArrays,
				onError: () => toast.error(translate('USERS.ORGANIZATION.HIERARCHY.SAVE_HIERARCHY.ERROR')),
			}
		)

	const [nodes, setNodes] = useNodesState(
		hierarchy.users.map(
			(node, index) =>
				({
					id: node.email,
					data: node,
					position: { x: 0, y: index * nodeGapHeight },
					type: 'ufinetNode',
					targetPosition: Position.Top,
					sourcePosition: Position.Bottom,
				} as Node<UserOrganizationalFlowInfoNode>)
		)
	)
	const [edges, setEdges, onEdgesChange] = useEdgesState(
		hierarchy.relationships.map(
			(node) =>
				({
					id: node.source + '->' + node.target,
					source: node.source,
					target: node.target,
					className: 'ufinet-line',
					animated: true,
				} as Edge)
		)
	)

	const onSave = () => {
		if (!reactFlowInstance) return

		const flow = reactFlowInstance.toObject()
		localStorage.setItem(flowKey, JSON.stringify(flow))
	}

	const onCharge = () => {
		const flow = localStorageDiagram()

		setNodes(flow.nodes || [])
		setEdges(flow.edges || [])
		setViewport(flow.viewport)
	}

	const getNodeChanges = (actualNodes: Node[], actualEdges: Edge[]): NodeChange[] => {
		const changes: NodeChange[] = []
		actualNodes.forEach((node) => {
			const incomers = getIncomers(node, actualNodes, actualEdges)
			const outgoers = getOutgoers(node, actualNodes, actualEdges)

			node.className =
				incomers.length === 0 &&
				outgoers.length === 0 &&
				node.data.userHierarchyType === UserOrganizationalType.SUPERVISOR
					? NodeState.Invalid
					: incomers.length === 0 && outgoers.length === 0
					? NodeState.Warning
					: NodeState.Valid

			changes.push({ id: node.id, type: 'dimensions' })
		})
		return changes
	}

	useEffect(() => {
		if (!newUser) return

		const actualNodes = [
			...nodes,
			{
				id: newUser.email,
				data: newUser,
				position: { x: 0, y: 0 },
				type: 'ufinetNode',
				targetPosition: Position.Top,
				sourcePosition: Position.Bottom,
			} as Node<UserOrganizationalFlowInfoNode>,
		]
		const changes = getNodeChanges(actualNodes, edges)

		setNodes(applyNodeChanges(changes, actualNodes))
		setNewUsers([...newUsers, newUser])
	}, [newUser])

	const getLayoutedElements = (nodes: Node[], edges: Edge[], direction = Direction.TopToBottom) => {
		const isHorizontal = direction === Direction.LeftToRight
		dagreGraph.setGraph({ rankdir: direction })

		nodes.forEach((node) => {
			dagreGraph.setNode(node.id, { width: nodeGapWidth, height: nodeGapHeight })
		})

		edges.forEach((edge) => {
			dagreGraph.setEdge(edge.source, edge.target)
		})

		dagre.layout(dagreGraph)

		nodes.forEach((node) => {
			const nodeWithPosition = dagreGraph.node(node.id)

			node.targetPosition = isHorizontal ? Position.Left : Position.Top
			node.sourcePosition = isHorizontal ? Position.Right : Position.Bottom

			node.position = {
				x: nodeWithPosition.x - nodeGapWidth / 2,
				y: nodeWithPosition.y - nodeGapHeight / 2,
			}

			return node
		})

		return { nodes, edges }
	}

	const onAutoLayout = useCallback(
		(direction) => {
			const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(nodes, edges, direction)

			setNodes([...layoutedNodes])
			setEdges([...layoutedEdges])
		},
		[nodes, edges, setNodes, setEdges]
	)

	const areNodesEquals = useCallback((localDiagram, backendDiagram) => {
		const localNodes = localDiagram.nodes.map((node: Node) => node.id)
		const backendNodes = backendDiagram.users.map((node: UserOrganizationalFlowInfoNode) => node.email)

		if (localNodes.length !== backendNodes.length) return false

		const diffEdges = localNodes.filter((node: Node) => !backendNodes.includes(node))

		if (diffEdges.length > 0) return false

		return true
	}, [])

	const areEdgesEquals = useCallback((localDiagram, backendDiagram) => {
		const localEdges = localDiagram.edges.map((edge: Edge) => edge.id)
		const backendEdges = backendDiagram.relationships.map(
			(edge: UserOrganizationalFlowInfoEdge) => edge.source + '->' + edge.target
		)

		if (localEdges.length !== backendEdges.length) return false

		const diffEdges = localEdges.filter((edge: Edge) => !backendEdges.includes(edge))

		if (diffEdges.length > 0) return false

		return true
	}, [])

	const areDiagramsEquals = useCallback(() => {
		const localDiagram = localStorageDiagram()
		if (!localDiagram) return false

		const backendDiagram = hierarchy

		if (
			localDiagram.nodes.length !== backendDiagram.users.length ||
			localDiagram.edges.length !== backendDiagram.relationships.length
		)
			return false

		return areNodesEquals(localDiagram, backendDiagram) && areEdgesEquals(localDiagram, backendDiagram)
	}, [localStorageDiagram, hierarchy, areNodesEquals, areEdgesEquals])

	useEffect(() => {
		if (!areDiagramsEquals()) {
			const changes = getNodeChanges(nodes, edges)
			setNodes(applyNodeChanges(changes, nodes))

			onAutoLayout(Direction.TopToBottom)
		} else {
			const flow = localStorageDiagram()
			setNodes(flow.nodes || [])
			setEdges(flow.edges || [])
		}
	}, [])

	useEffect(() => {
		if (!nodesInitialized) return

		const flow = localStorageDiagram()

		if (!flow) return

		setViewport(flow.viewport)
	}, [nodesInitialized])

	const onConnect = useCallback(
		(params: Connection) => {
			if (!params.source || !params.target) return

			const newEdgeId = `${params.source}->${params.target}`
			const deletedEdgeIndex = deletedEdges.findIndex((edge) => edge.id === newEdgeId)

			if (deletedEdgeIndex !== -1) {
				const updatedDeletedEdges = [...deletedEdges]
				updatedDeletedEdges.splice(deletedEdgeIndex, 1)
				setDeletedEdges(updatedDeletedEdges)
			}

			const newEdge: Edge = {
				id: newEdgeId,
				source: params.source,
				target: params.target,
				className: 'ufinet-line',
				animated: true,
			} as Edge
			const actualEdges = addEdge(newEdge, edges)
			const changes = getNodeChanges(nodes, actualEdges)

			setNodes(applyNodeChanges(changes, nodes))
			setEdges(actualEdges)

			setAddedEdges([...addedEdges, newEdge])
		},
		[nodes, edges, addedEdges]
	)

	const onNodesChange = useCallback(
		(changes: NodeChange[]) => {
			// TODO: this lines disable erase node functionality
			const hasRemoveChanges = changes.some((change) => change.type === 'remove')
			if (hasRemoveChanges) return

			const updatedNodes = applyNodeChanges(changes, nodes)
			setNodes(updatedNodes)
		},
		[nodes]
	)

	const onNodesDelete = useCallback(
		(deleted: Node[]) => {
			const actualNodes = nodes.filter((node) => deleted.some((deletedNode) => deletedNode.id !== node.id))
			const actualEdges = edges.filter((edge) =>
				deleted.some((deletedNode) => deletedNode.id !== edge.target && deletedNode.id !== edge.source)
			)
			const changes = getNodeChanges(nodes, actualEdges)
			setNodes(applyNodeChanges(changes, actualNodes))
			setEdges(
				deleted.reduce((acc: Edge[], node: Node) => {
					const connectedEdges = getConnectedEdges([node], edges)

					const remainingEdges = acc.filter((edge) => !connectedEdges.includes(edge))

					return [...remainingEdges]
				}, edges)
			)
		},
		[nodes, edges]
	)

	const onEdgesDelete = useCallback(
		(deleted: Edge[]) => {
			const actualEdges = edges.filter((e: Edge) => deleted.some((deletedEdge: Edge) => e.id !== deletedEdge.id))
			const changes = getNodeChanges(nodes, actualEdges)
			const deletedEdgesIds = deleted.map((edge) => edge.id)
			const addedEdgesToDelete = deletedEdgesIds.filter((edgeId) => addedEdges.some((edge) => edge.id === edgeId))
			const updatedAddEdges = addedEdges.filter((edge) => !addedEdgesToDelete.includes(edge.id))

			setNodes(applyNodeChanges(changes, nodes))
			setAddedEdges(updatedAddEdges)
			setDeletedEdges([...deletedEdges, ...deleted])
		},
		[nodes, edges, deletedEdges, addedEdges]
	)

	const removeFoundClass = useCallback(() => {
		nodes.forEach((node) => {
			if (!node.className) return

			const classList = node.className.split(' ')
			if (classList.includes(NodeState.Found)) {
				classList.splice(classList.indexOf(NodeState.Found), 1)
				node.className = classList.join(' ')
			}
		})

		setNodes([...nodes])
	}, [nodes, setNodes])

	const onSearchUsers = useCallback(
		(name: string) => {
			removeFoundClass()
			if (!name) return

			const searchTermLowerCase = name.toLowerCase()
			const foundNodes = nodes.filter((node) => {
				return node.data && node.data.name !== null && node.data.name.toLowerCase().includes(searchTermLowerCase)
			})

			if (foundNodes.length > 0) {
				const updatedNodes = [...nodes]

				foundNodes.forEach((node) => {
					if (!node.className) return

					const classList = node.className.split(' ')
					if (!classList.includes(NodeState.Found)) {
						classList.push(NodeState.Found)
					}

					node.className = classList.join(' ')
				})

				setNodes(updatedNodes)
			}
		},
		[nodes, setNodes, removeFoundClass]
	)

	const onSaveHerarchy = useCallback(() => {
		if (nodes.length === 0) return

		if (nodes.some((node) => node.className === NodeState.Invalid)) {
			nodes.forEach((node) => {
				if (node.className === NodeState.Invalid)
					toast.error(
						translate('USERS.ORGANIZATION.HIERARCHY.SAVE_HIERARCHY.ERROR.SAVE.SUPERVISOR', { name: node.data.name })
					)
			})
		} else {
			updateOrganizationFlow()
		}
	}, [nodes, deletedEdges, newUsers, addedEdges])

	return {
		edges,
		nodes,
		onConnect,
		onEdgesChange,
		onEdgesDelete,
		onNodesChange,
		onNodesDelete,
		onSave,
		onCharge,
		onAutoLayout,
		onSearchUsers,
		onSaveHerarchy,
		setReactFlowInstance,
		usersOrganizationalFlowLoading,
	}
}

export { useUserOrganizationFlow }
