import { faBookOpenReader, faGlasses, faUser, faUserTie } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UserOrganizationalFlowInfoNode } from 'modules/organization/diagram/domain/UserOrganizationalFlowInfoNode'
import { UserOrganizationalType } from 'modules/organization/user/type/domain/UserOrganizationalType'
import { FC } from 'react'
import { Handle, NodeProps, Position } from 'reactflow'

const icons = {
	[UserOrganizationalType.DIRECTOR]: faUserTie,
	[UserOrganizationalType.KAM]: faBookOpenReader,
	[UserOrganizationalType.SUPERVISOR]: faGlasses,
	[UserOrganizationalType.GENERAL]: faUser,
}

const UfinetNode: FC<NodeProps<UserOrganizationalFlowInfoNode>> = ({ data, targetPosition, sourcePosition }) => {
	return (
		<div className="ufinet-node shadow">
			{data.userHierarchyType !== UserOrganizationalType.DIRECTOR &&
			data.userHierarchyType !== UserOrganizationalType.GENERAL ? (
				<Handle
					className={'ufinet-edge ' + (targetPosition === Position.Left ? Position.Left : Position.Top)}
					type="target"
					position={targetPosition === Position.Left ? Position.Left : Position.Top}
				/>
			) : (
				''
			)}
			<div className="d-flex align-items-center px-1 py-2">
				<div className="user-img d-flex justify-content-center align-items-center m-2">
					<FontAwesomeIcon icon={icons[data.userHierarchyType] || faUser} className="icon" />
				</div>
				<div className="user-info">
					<div>
						<div className="name">{data.name}</div>
						<div className="hierachy-type">{data.userHierarchyType}</div>
						<div className="email">{data.email}</div>
					</div>
				</div>
			</div>
			{data.userHierarchyType !== UserOrganizationalType.SUPERVISOR &&
			data.userHierarchyType !== UserOrganizationalType.GENERAL ? (
				<Handle
					className={'ufinet-edge ' + (sourcePosition === Position.Right ? Position.Right : Position.Bottom)}
					type="source"
					position={sourcePosition === Position.Right ? Position.Right : Position.Bottom}
				/>
			) : (
				''
			)}
		</div>
	)
}

export { UfinetNode }
