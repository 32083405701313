import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { clsx } from 'clsx'
import { UserOrganizationalFlowInfoNode } from 'modules/organization/diagram/domain/UserOrganizationalFlowInfoNode'
import { UserOrganizationalInfoRepository } from 'modules/organization/user/info/domain/repository/UserOrganizationalInfoRepository'
import { UserOrganizationalTypeRepository } from 'modules/organization/user/type/domain/repository/UserOrganizationalTypeRepository'
import { FC } from 'react'
import { IUfinetSelectOption, UfinetAsyncButton, UfinetInput, UfinetSelect } from 'ufinet-web-components'
import { useTranslator } from 'ufinet-web-functions'
import { useUserOrganizationForm } from './hooks/useUserOrganizationForm'

type Props = Omit<React.HTMLAttributes<HTMLFormElement>, 'onSubmit'> & {
	userOrganizationalTypeRepository: UserOrganizationalTypeRepository
	userOrganizationalInfoRepository: UserOrganizationalInfoRepository
	onUserAdded: (userOrganizationalFlowInfoNode: UserOrganizationalFlowInfoNode) => void
}

const UserOrganizationForm: FC<Props> = ({
	userOrganizationalTypeRepository,
	userOrganizationalInfoRepository,
	onUserAdded,
	className,
	...htmlFormProps
}) => {
	const translate = useTranslator()

	const { form, user, onChange } = useUserOrganizationForm({
		userOrganizationalTypeRepository,
		userOrganizationalInfoRepository,
		onUserAdded,
	})

	return (
		<form
			{...htmlFormProps}
			onSubmit={form.formik.handleSubmit}
			className={clsx(className, 'position-relative d-flex flex-column gap-3 justify-content-start')}
		>
			<div className="row">
				<UfinetSelect
					className="col-12 col-md-4"
					requiredIcon
					labelTitle={translate('USERS.ORGANIZATION.FORM.USER_TYPE')}
					placeholder={translate('USERS.ORGANIZATION.FORM.USER_TYPE')}
					isClearable
					isMulti={false}
					value={user.type.selectedOption}
					options={user.type.options}
					onChange={(value) => onChange.userType(value as IUfinetSelectOption)}
					isLoadingOptions={user.type.loading}
					error={form.formik.errors.userType}
					isDisabled={form.loading}
				/>
			</div>
			<div className="row">
				<UfinetInput
					className="col-12 col-md-4"
					type="text"
					requiredIcon
					labelTitle={translate('USERS.ORGANIZATION.FORM.EMAIL')}
					placeholder={`${translate('USERS.ORGANIZATION.FORM.USER').toLowerCase()}@ufinet.com`}
					onChange={(changeEvent) => onChange.userEmail(changeEvent.target.value)}
					value={user.email}
					error={form.formik.errors.userEmail}
					solid={false}
					isDisabled={form.loading}
				/>
			</div>
			<div className="row">
				<div className="col-12 col-md-4">
					<UfinetAsyncButton
						className="p-5 w-auto"
						content={translate('USERS.ORGANIZATION.FORM.SUBMIT')}
						icon={faPlus}
						showSpinner={form.formik.isSubmitting || undefined}
						isDisabled={!form.complete || form.loading}
						onClick={form.formik.submitForm}
					/>
				</div>
			</div>
			<input className="d-none" type="submit" />
		</form>
	)
}

export { UserOrganizationForm }
