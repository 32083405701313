import { RoleFindResponse } from 'modules/role/application/find/dto/RoleFindResponse'
import { rest } from 'msw'

export const mockRoles: RoleFindResponse[] = []

export const roleHandlers = [
	rest.get('*api/role*', (req, res, ctx) => {
		return res(ctx.status(200), ctx.json(mockRoles))
	}),
]
