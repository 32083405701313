import { UserFindResponse } from 'modules/user/common/application/find/dto/UserFindResponse'
import { IntlShape } from 'react-intl'
import { defaultOptionsFetch, FetchAPI, FetchOptions, IAuthData, successNotifications } from 'ufinet-web-functions'
import { ExternalUserFindResponse } from './find/dto/ExternalUserFindResponse'
import { ExternalUserSaveRequest } from './save/dto/ExternalUserSaveRequest'
import { ExternalUserUpdateRequest } from './update/dto/ExternalUserUpdateRequest'

const url = `${process.env.REACT_APP_URL_BACK_PORTAL}api/user/external`

export const externalUserService = (authData: IAuthData, intl: IntlShape) => {
	const fetchOptions: FetchOptions = {
		...defaultOptionsFetch,
		authenticationData: authData,
	}

	return {
		findAll: async (options?: FetchOptions): Promise<UserFindResponse[]> =>
			await FetchAPI.get(url, { ...fetchOptions, ...options }),

		find: async (id: number, options?: FetchOptions): Promise<ExternalUserFindResponse> =>
			await FetchAPI.get(`${url}/${id}`, { ...fetchOptions, ...options }),

		create: async (criteria: ExternalUserSaveRequest, options?: FetchOptions): Promise<void> =>
			await FetchAPI.post(url, criteria, {
				...fetchOptions,
				notification: successNotifications.createRegistry(intl, criteria.email),
				...options,
			}),

		update: async (id: number, criteria: ExternalUserUpdateRequest, options?: FetchOptions): Promise<void> =>
			await FetchAPI.put(`${url}/${id}`, criteria, {
				...fetchOptions,
				notification: successNotifications.updateRegistry(intl, criteria.email),
				...options,
			}),

		delete: async (id: number, options?: FetchOptions): Promise<void> =>
			await FetchAPI.delete(`${url}/${id}`, {
				...fetchOptions,
				notification: successNotifications.deleteById(intl, {
					id,
					section: intl.formatMessage({ id: 'SECTION.USERS' }).toLowerCase(),
				}),
				...options,
			}),
	}
}
