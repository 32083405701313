import { defaultOptionsFetch, FetchAPI, FetchOptions, IAuthData } from 'ufinet-web-functions'
import { UserOrganizationalTypeRepository } from '../domain/repository/UserOrganizationalTypeRepository'

const url = `${process.env.REACT_APP_URL_BACK_PORTAL}api/organization/user/types`

export const HttpUserOrganizationalTypeRepository: (authData: IAuthData) => UserOrganizationalTypeRepository = (
	authData
) => {
	const fetchOptions: FetchOptions = {
		...defaultOptionsFetch,
		notifyError: false,
		authenticationData: authData,
		sendApiKey: true,
	}

	return {
		findAll: async (options?: FetchOptions) => await FetchAPI.get(url, { ...fetchOptions, ...options }),
	}
}
