import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'
import { applicationWso2Service } from 'modules/apps/wso2/application/applicationWso2Service'
import { ApplicationWso2, ApplicationWso2Local } from 'modules/apps/wso2/domain/ApplicationWso2'
import { FC, useCallback, useContext, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'
import {
	getEditPencilBodyTemplate,
	MultipleDeleter,
	Table,
	UfinetBox,
	UfinetButton,
	UfinetModal,
	UfinetSectionBox,
} from 'ufinet-web-components'
import { AuthContext, Authority, useAsync, useModal } from 'ufinet-web-functions'
import { Wso2AppModal } from './modal/Wso2AppModal'
import { colsApps } from './Wso2AppsColsData'

export const Wso2AppsPage: FC = () => {
	const intl = useIntl()
	const [apps, setApps] = useState<ApplicationWso2[]>()
	const [appId, setAppId] = useState<string>()
	const [selectedValues, setSelectedValues] = useState<Object[]>([])
	const [modal, showModal, hideModal] = useModal()

	const authData = useContext(AuthContext)
	const _applicationWso2Service = useMemo(() => applicationWso2Service(authData, intl), [authData])

	const roles = authData.userData?.authorities || []
	const permissions = Authority.getApplicationConfigPermissions(roles)

	const [loadingRecords, setLoadingRecords] = useState<boolean>(false)

	const getApplications = useCallback(async () => {
		setLoadingRecords(true)
		return await _applicationWso2Service.findAll()
	}, [_applicationWso2Service])

	const onApplicationsFetched = (apps: ApplicationWso2[]): void => {
		const parsedResult: ApplicationWso2Local[] = apps.map(
			(app) =>
				({
					...app,
					corporateGroupName: app.corporateGroup.name,
					activeName: app.active ? intl.formatMessage({ id: 'YES' }) : intl.formatMessage({ id: 'NO' }),
				} as ApplicationWso2Local)
		)
		setApps(parsedResult)
	}

	const onApplicationsFailedToFetch = (): void => {
		toast.error(intl.formatMessage({ id: 'APPLICATIONS.WSO2.FETCH.ERROR' }))
	}

	const runFinally = () => {
		setLoadingRecords(false)
	}

	const onModalSubmitSuccess = () => {
		fetchAndStore()
		hideModal()
	}

	const onModalSubmitError = () => {
		toast.error(
			intl.formatMessage({
				id: appId ? 'APPLICATION.WSO2.UPDATE.ERROR' : 'APPLICATION.WSO2.CREATE.ERROR',
			})
		)
	}

	const onModalFetchError = () => {
		toast.error(intl.formatMessage({ id: 'APPLICATION.WSO2.FETCH.ERROR' }))
		hideModal()
	}

	const fetchAndStore = useAsync(
		{
			asyncFn: getApplications,
			onSuccess: onApplicationsFetched,
			onFailure: onApplicationsFailedToFetch,
			runFinally: runFinally,
		},
		[]
	)

	const onSelectionChange = (values: Object[]) => {
		setSelectedValues(values)
	}

	const getHeadersButtons = () => (
		<>
			{permissions.canDelete && (
				<MultipleDeleter
					title={intl.formatMessage({ id: 'APPLICATION.WSO2.DELETE' })}
					text={intl.formatMessage({ id: 'APPLICATION.WSO2.DELETE.CONFIRM' })}
					setSelectedValues={setSelectedValues}
					selectedValues={selectedValues}
					deleteEndpoint={_applicationWso2Service.deleteMany}
					search={fetchAndStore}
				/>
			)}
			{permissions.canWrite && (
				<UfinetButton
					className="me-3"
					icon={faPlus}
					onClick={() => {
						setAppId(undefined)
						showModal()
					}}
					content={intl.formatMessage({ id: 'NEW.REGISTER' })}
				/>
			)}
		</>
	)

	return (
		<UfinetBox>
			<UfinetSectionBox>
				<Table
					selectedValues={selectedValues}
					onSelectionChange={permissions.canDelete ? onSelectionChange : undefined}
					headerButtons={getHeadersButtons()}
					actionBodyTemplate={
						permissions.canUpdate
							? (app: ApplicationWso2Local) =>
									getEditPencilBodyTemplate(app, () => {
										setAppId(app.id)
										showModal()
									})
							: undefined
					}
					content={apps}
					cols={colsApps}
					emptyMessage={loadingRecords ? intl.formatMessage({ id: 'LOADING_DOTS' }) : undefined}
				/>
			</UfinetSectionBox>
			<UfinetModal
				size="lg"
				show={modal}
				handleClose={hideModal}
				title={
					!appId
						? intl.formatMessage({ id: 'MODAL.CREATE.APP.TITLE' })
						: intl.formatMessage({ id: 'MODAL.EDIT.APP.TITLE' })
				}
			>
				<Wso2AppModal
					appId={appId}
					onSubmitSuccess={onModalSubmitSuccess}
					onSubmitError={onModalSubmitError}
					onFetchError={onModalFetchError}
				/>
			</UfinetModal>
		</UfinetBox>
	)
}
