import { IntlShape } from 'react-intl'
import { defaultOptionsFetch, FetchAPI, FetchOptions, IAuthData, successNotifications } from 'ufinet-web-functions'
import { CompanyDeleteRequest } from './delete/dto/CompanyDeleteRequest'
import { CompanyFindResponse } from './find/dto/CompanyFindResponse'
import { CompanySaveRequest } from './save/dto/CompanySaveRequest'
import { CompanyUpdateRequest } from './update/dto/CompanyUpdateRequest'

const url = `${process.env.REACT_APP_URL_BACK_PORTAL}api/company`

export const companyService = (authData: IAuthData, intl: IntlShape) => {
	const fetchOptions: FetchOptions = {
		...defaultOptionsFetch,
		authenticationData: authData,
	}

	return {
		findCompanies: async (): Promise<CompanyFindResponse[]> => await FetchAPI.get(url, fetchOptions),

		findCompany: async (id: number): Promise<CompanyFindResponse> => await FetchAPI.get(`${url}/${id}`, fetchOptions),

		create: async (request: CompanySaveRequest): Promise<void> =>
			await FetchAPI.post(`${url}`, request, {
				...fetchOptions,
				notification: successNotifications.createRegistry(intl, request.name),
			}),

		updateCompany: async (id: number, request: CompanyUpdateRequest): Promise<void> => {
			await FetchAPI.put(`${url}/${id}`, request, {
				...fetchOptions,
				notification: successNotifications.updateRegistry(intl, request.name),
			})
		},

		deleteCompany: async (id: number): Promise<any> =>
			await FetchAPI.delete(`${url}/${id}`, {
				...fetchOptions,
				notification: successNotifications.deleteById(intl, {
					id,
					section: intl.formatMessage({ id: 'SECTION.COMPANIES' }).toLowerCase(),
				}),
			}),

		deleteByIdsList: async (request: CompanyDeleteRequest): Promise<void> =>
			await FetchAPI.deleteWithBody(url, request, {
				...fetchOptions,
				notification: successNotifications.deleteByIdsList(intl, {
					section: intl.formatMessage({ id: 'SECTION.COMPANIES' }).toLowerCase(),
				}),
			}),
	}
}
