import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'
import { companyService } from 'modules/company/application/companiesService'
import { CompanyFindResponse } from 'modules/company/application/find/dto/CompanyFindResponse'
import { Company } from 'modules/company/domain/Company'

import { FC, useCallback, useContext, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'
import { MultipleDeleter, Table, UfinetBox, UfinetButton, UfinetModal, UfinetSectionBox } from 'ufinet-web-components'
import { AuthContext, Authority, useAsync, useModal } from 'ufinet-web-functions'
import { colsCompanies } from './CompaniesColsData'
import { NewCompanyModalBody } from './modal/NewCompanyModalBody'

export const CompaniesPage: FC = () => {
	const intl = useIntl()
	const [selectedValues, setSelectedValues] = useState<Company[]>()
	const [newModal, showNewModal, hideNewModal] = useModal()
	const [companies, setCompanies] = useState<Company[]>()

	const authData = useContext(AuthContext)
	const _companyService = useMemo(() => companyService(authData, intl), [authData, intl])

	const roles = authData.userData?.authorities || []
	const permissions = Authority.getSecurityConfigPermissions(roles)

	const [loadingRecords, setLoadingRecords] = useState<boolean>(false)

	const getCompanies = useCallback(async () => {
		setLoadingRecords(true)
		return await _companyService.findCompanies()
	}, [_companyService])

	const onCompaniesFetched = (companies: CompanyFindResponse[]): void => {
		const parsedResult: Company[] = companies.map((company) => ({
			...company,
		}))
		setCompanies(parsedResult)
	}

	const onCompaniesFailedToFetch = (): void => {
		toast.error(intl.formatMessage({ id: 'COMPANIES.FETCH.ERROR' }))
	}

	const runFinally = () => {
		setLoadingRecords(false)
	}

	const fetchAndStore = useAsync(
		{
			asyncFn: getCompanies,
			onSuccess: onCompaniesFetched,
			onFailure: onCompaniesFailedToFetch,
			runFinally: runFinally,
		},
		[]
	)

	const onSelectionChange = useCallback((values: Company[]) => setSelectedValues(values), [])

	const getHeadersButtons = () => {
		return (
			<>
				{permissions.canDelete && (
					<MultipleDeleter
						setSelectedValues={setSelectedValues}
						selectedValues={selectedValues}
						deleteEndpoint={_companyService.deleteByIdsList}
						search={fetchAndStore}
					/>
				)}
				{permissions.canWrite && (
					<UfinetButton
						className="me-3"
						icon={faPlus}
						onClick={() => {
							showNewModal()
						}}
						content={intl.formatMessage({ id: 'NEW.REGISTER' })}
					/>
				)}
			</>
		)
	}

	const onRowEditComplete = (data: Company) => {
		const { name, description, domain, domain2, domain3 } = data
		_companyService
			.updateCompany(data.id, { name, description, domain, domain2, domain3 })
			.then(fetchAndStore)
			.catch(console.error)
	}

	return (
		<UfinetBox>
			<UfinetSectionBox>
				<Table
					selectedValues={selectedValues}
					onSelectionChange={permissions.canDelete ? onSelectionChange : undefined}
					onRowEditComplete={permissions.canUpdate ? onRowEditComplete : undefined}
					cols={colsCompanies}
					content={companies}
					headerButtons={getHeadersButtons()}
					emptyMessage={loadingRecords ? intl.formatMessage({ id: 'LOADING_DOTS' }) : undefined}
				/>
				<UfinetModal
					size="lg"
					show={newModal}
					handleClose={hideNewModal}
					title={intl.formatMessage({ id: 'MODAL.NEW.REGISTER.TITLE' })}
				>
					<NewCompanyModalBody search={fetchAndStore} hideModal={hideNewModal} />
				</UfinetModal>
			</UfinetSectionBox>
		</UfinetBox>
	)
}
