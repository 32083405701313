import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'

import { deepEqual } from 'fast-equals'
import { useFormik } from 'formik'
import { applicationWso2EditRequestFromFormData } from 'modules/apps/wso2/application/applicationWso2EditRequestFromFormData'
import { applicationWso2Service } from 'modules/apps/wso2/application/applicationWso2Service'
import { formDataFromApplicationWso2 } from 'modules/apps/wso2/application/formDataFromApplicationWso2'
import { ApplicationWso2EditRequest } from 'modules/apps/wso2/application/update/dto/ApplicationWso2EditRequest'
import { ApplicationWso2FormData, initialWso2AppForm } from 'modules/apps/wso2/domain/ApplicationWso2FormData'

import { FC, PropsWithChildren, useCallback, useContext, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import {
	CorporateGroupSelect,
	CorporateGroupSelectHandle,
	Loading,
	UfinetActionButton,
	UfinetActionButtonHandle,
	UfinetButton,
	UfinetCheckbox,
	UfinetInput,
} from 'ufinet-web-components'
import {
	AuthContext,
	FetchOptions,
	onFormikChanges,
	onFormikCheckboxChanges,
	onFormikTextChanges,
	onFormikTrimField,
	useAsync,
} from 'ufinet-web-functions'

import * as Yup from 'yup'

type Props = {
	appId?: string
	onSubmitSuccess: (app: Required<ApplicationWso2FormData>) => void
	onFetchError?: (params: { appId?: string; error?: any }) => void
	onSubmitError?: (params: { appId?: string; error?: any }) => void
}

// Multi-purpose (create and edit)
export const Wso2AppModal: FC<PropsWithChildren<Props>> = ({
	appId,
	onSubmitSuccess = () => {},
	onFetchError = () => {},
	onSubmitError = () => {},
}) => {
	const intl = useIntl()
	const submitButtonRef = useRef<UfinetActionButtonHandle>(null)
	const corporateGroupRef = useRef<CorporateGroupSelectHandle>(null)

	const authData = useContext(AuthContext)
	const _applicationWso2Service = useMemo(() => applicationWso2Service(authData, intl), [authData, intl])

	const [initialData, setInitialData] = useState<ApplicationWso2FormData>()
	const [loading, setLoading] = useState<boolean>(false)

	const onTextChange = useCallback(onFormikTextChanges, [])
	const onTrimTextChange = useCallback(onFormikTrimField, [])
	const onCheckboxChange = useCallback(onFormikCheckboxChanges, [])

	const dataFormSchema = Yup.object().shape({
		applicationName: Yup.string().required(intl.formatMessage({ id: 'ERROR.REQUIRED' })),
		corporateGroup: Yup.object().shape({
			label: Yup.string().required(intl.formatMessage({ id: 'ERROR.REQUIRED' })),
			value: Yup.string().required(intl.formatMessage({ id: 'ERROR.REQUIRED' })),
		}),
		active: Yup.boolean().nullable(),
	})

	const formik = useFormik<ApplicationWso2FormData>({
		initialValues: initialWso2AppForm,
		validationSchema: dataFormSchema,
		onSubmit: (application) => {
			submitApplication(application)
		},
		validateOnChange: false,
		validateOnBlur: false,
	})

	const getInitialApplication = useCallback(async (): Promise<ApplicationWso2FormData> => {
		setLoading(true)
		if (appId) return await _applicationWso2Service.findById(appId).then(formDataFromApplicationWso2)
		else return initialWso2AppForm
	}, [_applicationWso2Service, appId])

	const submitApplication = (application: ApplicationWso2FormData): void => {
		const reqOptions: FetchOptions = { notifyError: false }
		const reqData: ApplicationWso2EditRequest = applicationWso2EditRequestFromFormData(application)
		const req = appId
			? _applicationWso2Service.update(appId, reqData, reqOptions)
			: _applicationWso2Service.create(reqData, reqOptions)

		submitButtonRef.current?.changeActionStatus(true)
		setLoading(true)

		req
			.then(() => {
				onSubmitSuccess(application)
				return application
			})
			.catch((error) => {
				onSubmitError({ appId, error })
			})
			.finally(() => {
				submitButtonRef.current?.changeActionStatus(false)
				setLoading(false)
			})
	}

	useAsync(
		{
			asyncFn: () => {
				corporateGroupRef.current?.fillAll()
				return getInitialApplication()
			},
			onSuccess: (formData) => {
				formik.setValues(formData)
				setInitialData(formData)
			},
			onFailure: onFetchError,
			runFinally: () => setLoading(false),
		},
		[getInitialApplication]
	)

	return (
		<form
			onSubmit={formik.handleSubmit}
			className={`position-relative d-flex flex-column justify-content-center p-0 m-0 ${
				loading ? 'form-disabled' : ''
			}`}
		>
			<div className="row">
				<div className="row">
					<UfinetInput
						requiredIcon
						error={formik.errors.applicationName}
						type="text"
						labelTitle={intl.formatMessage({ id: 'APPLICATION.NAME' })}
						tooltipTitle={intl.formatMessage({ id: 'APPLICATION.NAME.TOOLTIP' })}
						className="col-6"
						onChange={onTextChange(formik, 'applicationName')}
						value={formik.values.applicationName}
						onBlur={() => onTrimTextChange(formik, 'applicationName')}
						solid={false}
					/>
					<CorporateGroupSelect
						requiredIcon
						ref={corporateGroupRef}
						labelTitle={intl.formatMessage({ id: 'APPLICATION.WSO2.CORPORATE_GROUP' })}
						tooltipTitle={intl.formatMessage({ id: 'APPLICATION.WSO2.CORPORATE_GROUP.TOOLTIP' })}
						isMulti={false}
						className="col-6"
						onChange={onFormikChanges(formik, 'corporateGroup')}
						value={formik.values.corporateGroup}
						error={formik.errors.corporateGroup?.value}
						isDisabled={false}
					/>
				</div>
				<div className="row pt-4">
					<UfinetCheckbox
						className="pe-6"
						title={intl.formatMessage({ id: 'APPLICATION.WSO2.ACTIVE' })}
						checked={formik.values.active}
						value="active"
						onChange={onCheckboxChange(formik, 'active')}
					/>
				</div>
			</div>
			<div className="row pb-4">
				<UfinetActionButton
					ref={submitButtonRef}
					className="mt-5 ms-3 p-5 w-auto"
					content={intl.formatMessage({ id: appId ? 'APPLICATION.UPDATE' : 'APPLICATION.ADD' })}
					icon={faPlus}
					onClick={() => formik.handleSubmit()}
					isDisabled={!!appId && deepEqual(formik.values, initialData)}
				/>
				<UfinetButton
					secondaryButton
					className="mt-5 ms-3 p-5 w-auto"
					content={intl.formatMessage({ id: 'CLEAR' })}
					icon={faTrash}
					onClick={() => formik.resetForm()}
					isDisabled={false}
				/>
			</div>
			<input className="d-none" type="submit" />

			{loading && <Loading />}
		</form>
	)
}
