import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import * as ReactDOM from 'react-dom'
import reportWebVitals from './reportWebVitals'

import 'reactflow/dist/style.css'
import 'ufinet-web-components/dist/index.css'

import './sass/style.scss'

import { PortalI18nProvider } from 'components/common/i18n/PortalI18nProvider'
import { Provider } from 'react-redux'
import { AppRoutes } from 'routing/AppRoutes'
import { worker } from 'tests/mocks/browser'
import { AuthProvider } from 'ufinet-web-components'
import packageJson from '../package.json'
import { store } from './store/store'

// Start the mocking conditionally
if (process.env.REACT_APP_ENV === 'TEST') {
	worker.start()
}

console.info(packageJson.version)

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			cacheTime: 300000,
			refetchOnWindowFocus: false,
			retry: false,
		},
	},
})

ReactDOM.render(
	<>
		<Provider store={store}>
			<AuthProvider>
				<PortalI18nProvider>
					<QueryClientProvider client={queryClient}>
						<AppRoutes />
					</QueryClientProvider>
				</PortalI18nProvider>
			</AuthProvider>
		</Provider>
	</>,
	document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
