import { ApplicationWso2 } from 'modules/apps/wso2/domain/ApplicationWso2'
import { ColData, DivWrapped } from 'ufinet-web-components'
import { convertToLocalTZ } from 'ufinet-web-functions'

export const colsApps: ColData[] = [
	{ field: 'applicationName', header: 'COL.APP.WSO2.NAME', nonFilterable: false, editable: false },
	{
		field: 'corporateGroupName',
		header: 'COL.APP.WSO2.CORPORATE_GROUP',
		width: '220px',
		nonFilterable: false,
		editable: true,
	},
	{
		field: 'activeName',
		header: 'COL.APP.WSO2.ACTIVE',
		nonFilterable: false,
		editable: false,
	},
	{
		field: 'insertedBy',
		header: 'COL.CREATE_BY',
		nonFilterable: true,
		body: (row: ApplicationWso2) => <DivWrapped className="ufinet-table-cell" input={row.insertedBy} separator="@" />,
	},
	{
		field: 'insertedAt',
		header: 'COL.CREATE_AT',
		nonFilterable: true,
		body: (row: ApplicationWso2) => convertToLocalTZ(row.insertedAt),
	},
	{
		field: 'lastUpdatedBy',
		header: 'COL.ACT_BY',
		nonFilterable: true,
		body: (row: ApplicationWso2) => (
			<DivWrapped className="ufinet-table-cell" input={row.lastUpdatedBy} separator="@" />
		),
	},
	{
		field: 'lastUpdatedAt',
		header: 'COL.ACT_AT',
		nonFilterable: true,
		body: (row: ApplicationWso2) => convertToLocalTZ(row.lastUpdatedAt),
	},
]
