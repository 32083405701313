import { RoleTypes } from 'modules/role/domain/types/RoleTypes'
import { IdAndName } from 'modules/user/common/domain/IdAndName'
import { IdAndNameAndType } from 'modules/user/common/domain/IdAndNameAndType'
import { UserTypesEnum } from 'ufinet-web-functions'

export type ExternalUser = {
	userType: UserTypesEnum
	email: string
	name: string
	firstSurname?: string
	secondSurname?: string
	phone?: string
	company?: IdAndName<number>
	countries: IdAndName<string>[]
	corporateGroups: IdAndName<string>[]
	clients: IdAndName<string>[]
	roles: IdAndNameAndType<number, RoleTypes>[]
	admin?: boolean
}

export const initialExternalUser: ExternalUser = {
	userType: UserTypesEnum.EXTERNAL_USER,
	email: '',
	name: '',
	firstSurname: undefined,
	secondSurname: undefined,
	phone: undefined,
	company: undefined,
	countries: [],
	corporateGroups: [],
	clients: [],
	roles: [],
	admin: undefined,
}
