import { useUserOrganizationalFlowFindAllQuery } from 'modules/organization/diagram/application/find/UserOrganizationalFlowFindQueries'
import { UserOrganizationalFlowInfoNode } from 'modules/organization/diagram/domain/UserOrganizationalFlowInfoNode'
import { HttpUserOrganizationalFlowRepository } from 'modules/organization/diagram/infrastructure/HttpUserOrganizationalFlowRepository'
import { HttpUserOrganizationalInfoRepository } from 'modules/organization/user/info/infrastructure/HttpUserOrganizationalInfoRepository'
import { HttpUserOrganizationalTypeRepository } from 'modules/organization/user/type/infrastructure/HttpUserOrganizationalTypeRepository'
import { FC, useMemo, useState } from 'react'
import { ReactFlowProvider } from 'reactflow'
import { Loading } from 'ufinet-web-components'
import { Authority, useAuthData } from 'ufinet-web-functions'
import { UserOrganizationForm } from './form/UserOrganizationForm'
import { UserOrganizationFlow } from './hierarchy/UserOrganizationFlow'
import './hierarchy/styles/styles.scss'

const UserOrganization: FC = () => {
	const authData = useAuthData()
	const roles = authData.userData?.authorities || []
	const securityPermissions = Authority.getSecurityConfigPermissions(roles)

	const userOrganizationalTypeRepository = useMemo(() => HttpUserOrganizationalTypeRepository(authData), [authData])
	const userOrganizationalInfoRepository = useMemo(() => HttpUserOrganizationalInfoRepository(authData), [authData])
	const userOrganizationalFlowRepository = useMemo(() => HttpUserOrganizationalFlowRepository(authData), [authData])

	const [newUser, setNewUser] = useState<UserOrganizationalFlowInfoNode>()

	const { data: usersOrganizationalFlowData, isLoading: usersOrganizationalFlowLoading } =
		useUserOrganizationalFlowFindAllQuery(userOrganizationalFlowRepository)

	return (
		<div className="d-flex flex-column gap-5">
			<div className="row ufinet-theme">
				{usersOrganizationalFlowLoading || !usersOrganizationalFlowData ? (
					<Loading />
				) : (
					<ReactFlowProvider>
						<UserOrganizationFlow
							hierarchy={usersOrganizationalFlowData}
							newUser={newUser}
							repository={userOrganizationalFlowRepository}
						/>
					</ReactFlowProvider>
				)}
			</div>
			{securityPermissions.canWrite && (
				<div className="row">
					<UserOrganizationForm
						userOrganizationalTypeRepository={userOrganizationalTypeRepository}
						userOrganizationalInfoRepository={userOrganizationalInfoRepository}
						onUserAdded={setNewUser}
					/>
				</div>
			)}
		</div>
	)
}

export { UserOrganization }
