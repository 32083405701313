import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import { CompanySelect } from 'components/selects/CompanySelect'
import { RoleSelect } from 'components/selects/RoleSelect'
import { useFormik } from 'formik'
import { RoleTypes } from 'modules/role/domain/types/RoleTypes'
import { externalUserModelFromResponse } from 'modules/user/external/application/externalUserModelFromResponse'
import { externalUserService } from 'modules/user/external/application/externalUserService'
import { ExternalUser, initialExternalUser } from 'modules/user/external/domain/ExternalUser'
import { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'

import {
	ClientSelect,
	ClientSelectHandle,
	ClientSelectOption,
	CorporateGroupSelect,
	CorporateGroupSelectHandle,
	CountrySelect,
	CountrySelectHandle,
	IUfinetSelectOption,
	Loading,
	UfinetButton,
	UfinetInput,
} from 'ufinet-web-components'
import {
	AuthContext,
	FetchOptions,
	IdAndName,
	UserTypesEnum,
	compareStringIgnoreCase,
	domainMailRegExp,
	onFormikTrimField,
	phoneRegExp,
	useInternalUser,
	useTranslator,
	useUserDomain,
} from 'ufinet-web-functions'
import * as Yup from 'yup'
import { roleToOption } from '../mappers/roleToOptionMapper'
import { AdminPermissionsCheckBox } from './AdminPermissionsCheckBox'

type ExternalUserWithType = ExternalUser & { type: UserTypesEnum }

type ExternalUserModalProps = {
	userId?: number
	afterSubmit?: () => void
	onUserSubmitted?: (newUser: ExternalUser) => void
	onSubmitError?: (error?: any) => void
}

const ExternalUserModal: FC<ExternalUserModalProps> = ({
	userId,
	afterSubmit = () => {},
	onUserSubmitted = () => {},
	onSubmitError = () => {},
}) => {
	const intl = useIntl()
	const translate = useTranslator()

	const authData = useContext(AuthContext)
	const internalUser = useInternalUser()
	const userDomain = useUserDomain()

	const [loading, setLoading] = useState<boolean>(!!userId)
	const [canEditRoles, setCanEditRoles] = useState<boolean>(true)

	const _externalUsersService = useMemo(() => externalUserService(authData, intl), [authData, intl])

	const countryRef = useRef<CountrySelectHandle>(null)
	const clientRef = useRef<ClientSelectHandle>(null)
	const corporateGroupRef = useRef<CorporateGroupSelectHandle>(null)

	const [hasAdminPermissions, setHasAdminPermissions] = useState<boolean>()
	useEffect(() => {
		formik.setFieldValue('admin', hasAdminPermissions)
	}, [hasAdminPermissions])

	const dataFormSchema = Yup.object().shape({
		type: Yup.string().oneOf([UserTypesEnum.EXTERNAL_USER]).required(),
		email: Yup.string()
			.required(translate('ERROR.REQUIRED'))
			.email(translate('ERROR.MAIL.INVALID'))
			.test(
				'email domain validation',
				internalUser
					? translate('ERROR.MAIL.INVALID.EXTERNAL')
					: translate('ERROR.MAIL.INVALID.EXTERNAL.DOMAIN', { domain: userDomain }),
				(inputEmail) => {
					const regexp = domainMailRegExp(userDomain || '')
					const userInputMatchesUserDomain = !inputEmail ? false : regexp.test(inputEmail)
					return internalUser ? !userInputMatchesUserDomain : userInputMatchesUserDomain
				}
			),
		name: Yup.string().required(translate('ERROR.REQUIRED')),
		firstSurname: Yup.string().notRequired().nullable(),
		secondSurname: Yup.string().notRequired().nullable(),
		phone: Yup.string().notRequired().nullable().matches(phoneRegExp, translate('ERROR.PHONE.INVALID')),
		company: Yup.object().required(translate('ERROR.REQUIRED')),
		countries: Yup.array().notRequired(),
		corporateGroups: Yup.array().notRequired(),
		clients: Yup.array().notRequired(),
		roles: Yup.array().notRequired(),
		idAdmin: Yup.boolean().notRequired(),
	})

	const formik = useFormik<ExternalUserWithType>({
		initialValues: { ...initialExternalUser, type: UserTypesEnum.EXTERNAL_USER },
		validationSchema: dataFormSchema,
		onSubmit: (user) => {
			submitUser(user)
			afterSubmit()
		},
		validateOnChange: false,
		validateOnBlur: false,
		enableReinitialize: true,
		onReset: (values, form) => {
			form.setValues({
				...initialExternalUser,
				type: UserTypesEnum.EXTERNAL_USER,
				roles: canEditRoles ? initialExternalUser.roles : formik.values.roles,
			})
		},
	})

	const fillInWithExistingUser = useCallback(
		(userId: number): void => {
			_externalUsersService
				.find(userId)
				.then((user) => {
					formik.setValues({
						...externalUserModelFromResponse(user),
						type: UserTypesEnum.EXTERNAL_USER,
					} as ExternalUserWithType)
					setCanEditRoles(!compareStringIgnoreCase(user.email, authData.userData?.username))
					setHasAdminPermissions(user.admin)
					return user
				})
				.catch(console.error)
				.finally(() => setLoading(false))
		},
		[_externalUsersService, authData.userData?.username]
	)

	useEffect(() => {
		if (!userId) return
		fillInWithExistingUser(userId)
	}, [fillInWithExistingUser, userId])

	const onCountryChange = (countries: IUfinetSelectOption[]) => {
		const modelCountries: IdAndName<string>[] = countries.map(
			(country) =>
				({
					id: country.value,
					name: country.label,
				} as IdAndName<string>)
		)

		// Set model values
		formik.setFieldValue('countries', modelCountries)

		// Empty dependent selects
		corporateGroupRef.current?.clearSelect()
		formik.setFieldValue('corporateGroups', [])

		clientRef.current?.clearSelect()
		formik.setFieldValue('clients', [])

		// Limit corporate group dropdown options
		corporateGroupRef.current?.fillByCountries(modelCountries.map((country) => country.id))
	}

	const onCorporateGroupChange = (groups: IUfinetSelectOption[]) => {
		const modelCorporateGroups: IdAndName<string>[] = groups.map(
			(group) =>
				({
					id: group.value,
					name: group.label,
				} as IdAndName<string>)
		)
		// Set model values
		formik.setFieldValue('corporateGroups', modelCorporateGroups)

		// Empty dependent selects
		clientRef.current?.clearSelect()
		formik.setFieldValue('clients', [])
		// Limit corporate group dropdown options
		clientRef.current?.fillSelect(
			formik.values.countries.map((c) => c.id),
			modelCorporateGroups.map((cg) => cg.id)
		)
	}

	const onClientChange = (clients: ClientSelectOption[]) => {
		const modelClients: IdAndName<string>[] = clients.map(
			({ value, label, ...rest }) =>
				({
					id: value,
					name: label,
					...rest,
				} as IdAndName<string> & ClientSelectOption)
		)

		// Set model values
		formik.setFieldValue('clients', modelClients)
	}

	const submitUser = (formUser: ExternalUser): void => {
		const reqOptions: FetchOptions = { notifyError: false }
		const req = userId
			? _externalUsersService.update(userId, formUser, reqOptions)
			: _externalUsersService.create(formUser, reqOptions)

		req
			.then(() => {
				onUserSubmitted(formUser)
				return formUser
			})
			.catch((err) => {
				toast.error(translate(userId ? 'USER.UPDATE.ERROR' : 'USER.CREATION.ERROR'))
				onSubmitError(err)
			})
	}

	return (
		<form
			onSubmit={formik.handleSubmit}
			className={`position-relative d-flex flex-column justify-content-center p-0 m-0 ${
				loading ? 'form-disabled' : ''
			}`}
		>
			<div className="row">
				{internalUser && (
					<div className="row">
						<AdminPermissionsCheckBox
							className="col-12 mb-4 d-flex justify-content-end"
							value={hasAdminPermissions}
							onChange={setHasAdminPermissions}
							error={formik.errors.admin}
						/>
					</div>
				)}
				<div className="row">
					{/* EMAIL */}
					<UfinetInput
						{...formik.getFieldProps}
						requiredIcon
						className="col-4"
						error={formik.errors.email}
						type="text"
						labelTitle={translate('EMAIL')}
						tooltipTitle={translate('EMAIL.TOOLTIP')}
						onChange={(e) => formik.setFieldValue('email', e.target?.value || initialExternalUser.email)}
						onBlur={() => onFormikTrimField(formik, 'email')}
						value={formik.values.email}
						solid={false}
						autofocus
					/>
					{/* COMPANY */}
					<CompanySelect
						requiredIcon
						className="col-4"
						error={formik.errors.company}
						value={
							formik.values.company
								? {
										label: formik.values.company.name,
										value: formik.values.company.id.toString(),
								  }
								: {
										label: initialExternalUser.company?.name || '',
										value: initialExternalUser.company?.id?.toString() || '',
								  }
						}
						onChange={(company) => {
							const companyOption = company as IUfinetSelectOption
							const newCompanyValue = company
								? ({
										id: +companyOption.value,
										name: companyOption.label,
								  } as IdAndName<number>)
								: initialExternalUser.company
							formik.setFieldValue('company', newCompanyValue)
						}}
					/>
					{/* PHONE */}
					<UfinetInput
						requiredIcon={false}
						className="col-4"
						error={formik.errors.phone}
						type="text"
						labelTitle={translate('PHONE')}
						tooltipTitle={translate('PHONE.TOOLTIP')}
						onChange={(e) => formik.setFieldValue('phone', e.target?.value || initialExternalUser.phone)}
						onBlur={() => onFormikTrimField(formik, 'phone')}
						value={formik.values.phone || ''}
						solid={false}
					/>
				</div>
				<div className="row pt-4">
					<div className="row mt-4">
						{/* NAME */}
						<UfinetInput
							requiredIcon
							className="col-4"
							error={formik.errors.name}
							type="text"
							labelTitle={translate('NAME')}
							tooltipTitle={translate('NAME.TOOLTIP')}
							onChange={(e) => formik.setFieldValue('name', e.target?.value || initialExternalUser.name)}
							onBlur={() => onFormikTrimField(formik, 'name')}
							value={formik.values.name}
							solid={false}
						/>
						{/* SURNAME 1 */}
						<UfinetInput
							className="col-4"
							error={formik.errors.firstSurname}
							type="text"
							labelTitle={translate('SURNAME_1')}
							tooltipTitle={translate('SURNAME_1.TOOLTIP')}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
								formik.setFieldValue('firstSurname', e.target?.value || initialExternalUser.firstSurname)
							}
							onBlur={() => onFormikTrimField(formik, 'firstSurname')}
							value={formik.values.firstSurname || ''}
							solid={false}
						/>
						{/* SURNAME 2 */}
						<UfinetInput
							className="col-4"
							error={formik.errors.secondSurname}
							type="text"
							labelTitle={translate('SURNAME_2')}
							tooltipTitle={translate('SURNAME_2.TOOLTIP')}
							onChange={(e) =>
								formik.setFieldValue('secondSurname', e.target?.value || initialExternalUser.secondSurname)
							}
							onBlur={() => onFormikTrimField(formik, 'secondSurname')}
							value={formik.values.secondSurname || ''}
							solid={false}
						/>
					</div>
				</div>
				<div className="row pt-4">
					{/* ROLES */}
					<RoleSelect
						requiredIcon={false}
						className="col-4"
						error={formik.errors.roles}
						isMulti
						isDisabled={!canEditRoles}
						value={formik.values.roles.map(roleToOption)}
						includeRoleTypes={[RoleTypes.EXTERNAL_ROLE]}
						tooltipWarning={
							formik.values.roles.some((role) => role.type === RoleTypes.INTERNAL_ROLE)
								? translate('ROLES.CROSSED')
								: !canEditRoles
								? translate('ROLES.EDIT.ERROR')
								: undefined
						}
						onChange={(roles) => {
							const rolesOptions = roles as IUfinetSelectOption[]

							const selectedRoles = rolesOptions.map(
								(role) =>
									({
										id: +role.value,
										name: role.label,
										type: role.type,
									} as IdAndName<number> & { type: RoleTypes })
							)
							formik.setFieldValue('roles', selectedRoles)
						}}
					/>
				</div>
				<div className="row pt-4">
					{/* COUNTRIES */}
					<CountrySelect
						ref={countryRef}
						requiredIcon={false}
						isMulti
						withSelectAll
						className="col-4"
						onChange={(countries) => onCountryChange(countries as IUfinetSelectOption[])}
						value={formik.values.countries.map(
							(country) =>
								({
									label: country.name,
									value: country.id,
								} as IUfinetSelectOption)
						)}
						error={formik.errors.countries}
						isDisabled={false}
					/>

					{/* CORPORATE GROUPS */}
					<CorporateGroupSelect
						requiredIcon={false}
						ref={corporateGroupRef}
						labelTitle={translate('USER.CORPORATE_GROUPS')}
						tooltipTitle={translate('USER.CORPORATE_GROUPS.TOOLTIP')}
						isMulti
						withSelectAll
						className="col-4"
						onChange={(groups) => onCorporateGroupChange(groups as IUfinetSelectOption[])}
						value={formik.values.corporateGroups.map(
							(cg) =>
								({
									label: cg.name,
									value: cg.id,
								} as IUfinetSelectOption)
						)}
						error={formik.errors.corporateGroups}
						isDisabled={formik.values.countries.length === 0}
					/>

					{/* CLIENTS */}
					<ClientSelect
						requiredIcon={false}
						ref={clientRef}
						className="col-4"
						isMulti
						withSelectAll
						value={[...formik.values.clients].map(
							(client) =>
								({
									label: client.name,
									value: client.id,
								} as IUfinetSelectOption)
						)}
						error={formik.errors.clients}
						onChange={(clients) => onClientChange(clients as ClientSelectOption[])}
						corporateGroupIds={formik.values.corporateGroups.map((cg) => cg.id)}
						isDisabled={formik.values.corporateGroups.length === 0}
					/>
				</div>

				<div className="row">
					<UfinetButton
						className="mt-5 ms-3 p-5 w-auto"
						content={translate(userId ? 'USER.UPDATE' : 'USER.ADD')}
						icon={faPlus}
						onClick={() => formik.handleSubmit()}
						isDisabled={false}
					/>
					<UfinetButton
						secondaryButton
						className="mt-5 ms-3 p-5 w-auto"
						content={translate('CLEAR')}
						icon={faTrash}
						onClick={formik.handleReset}
						isDisabled={false}
					/>
				</div>
			</div>
			<button className="d-none" type="submit" onClick={() => formik.handleSubmit()} />

			{loading && <Loading />}
		</form>
	)
}

export default ExternalUserModal
