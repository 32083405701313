import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'
import { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'

import { faFilter } from '@fortawesome/free-solid-svg-icons'
import { RoleSelectTooltipButton } from 'components/buttons/RoleSelectTooltipButton'
import { RoleTypes } from 'modules/role/domain/types/RoleTypes'
import { UserFindRequest } from 'modules/user/common/application/find/dto/UserFindRequest'
import { UserFindPagedResponse, UserFindResponse } from 'modules/user/common/application/find/dto/UserFindResponse'
import { userService } from 'modules/user/common/application/userService'
import { UserPropertyMap } from 'modules/user/common/domain/UserPropertyMap'
import { DataTableFilterMeta, DataTablePFSEvent } from 'primereact/datatable'
import {
	formatTableFilters,
	getEditPencilBodyTemplate,
	IUfinetSelectOption,
	MultipleDeleter,
	Table,
	TableHandle,
	tableSortValueToSortDirection,
	UfinetBox,
	UfinetButton,
	UfinetModal,
	UfinetSectionBox,
} from 'ufinet-web-components'
import {
	AuthContext,
	Authority,
	defaultPagingParameters,
	PagingData,
	useAsync,
	useInternalUser,
	useModal,
	UserTypesEnum,
	useTranslator,
} from 'ufinet-web-functions'
import ExternalUserModal from '../../components/user/modal/external/ExternalUserModal'
import InternalUserModal from '../../components/user/modal/internal/InternalUserModal'
import { usersTableColumns } from '../../components/user/UsersTableColumns'

const initialRequestData: UserFindRequest = {
	...defaultPagingParameters,
} as UserFindRequest

type FormattedUserResponse = UserFindResponse & { idAndType: string }

const UsersPage: FC = () => {
	const intl = useIntl()
	const translate = useTranslator()
	const [selectedValues, setSelectedValues] = useState<FormattedUserResponse[]>()
	const [internalUserModal, showInternalUserModal, hideInternalUserModal] = useModal()
	const [externalUserModal, showExternalUserModal, hideExternalUserModal] = useModal()
	const [users, setUsers] = useState<FormattedUserResponse[]>()

	const [userId, setUserId] = useState<number>() // User being edited

	const authData = useContext(AuthContext)
	const _userService = useMemo(() => userService(authData, intl), [authData, intl])

	const [userRequestData, setUserRequestData] = useState<UserFindRequest>(initialRequestData)
	const [paging, setPaging] = useState<PagingData>()

	const roles = authData.userData?.authorities || []
	const authPermissions = Authority.getSecurityConfigPermissions(roles)
	const internalUser = useInternalUser()

	const [loadingRecords, setLoadingRecords] = useState<boolean>(false)
	const [tablePFSEvent, setTablePFSEvent] = useState<DataTablePFSEvent>()

	const colsUser = useMemo(() => usersTableColumns(translate, { internalUser }), [internalUser, translate])

	const tableRef = useRef<TableHandle>(null)

	const [filteredRoles, setFilteredRoles] = useState<IUfinetSelectOption[]>([])

	const getUsers = useCallback(
		async (params: UserFindRequest = userRequestData) => {
			setLoadingRecords(true)
			return await (!params ? _userService.findAll() : _userService.find(params))
		},
		[_userService, userRequestData]
	)

	const onUsersFetched = (findResponse: UserFindPagedResponse): void => {
		// The table's data key must be a combo of user + type.
		// Else, it can't tell an internal with ID "n" from and external user with ID "n"
		setUsers(
			findResponse.users.map((user) => ({
				...user,
				idAndType: `${user.id}-${user.userType}`,
			}))
		)
		setPaging(findResponse.pagingData)
	}

	const onUsersFailedToFetch = (): void => {
		toast.error(translate('USERS.FETCH.ERROR'))
	}

	const runFinally = () => {
		setLoadingRecords(false)
	}

	const fetchAndStore = useAsync(
		{
			asyncFn: getUsers,
			onSuccess: onUsersFetched,
			onFailure: onUsersFailedToFetch,
			runFinally: runFinally,
			options: { deep: true },
		},
		[userRequestData]
	)

	// TABLE FUNCTIONS

	const onSelectionChange = useCallback((values: FormattedUserResponse[]) => setSelectedValues(values), [])

	const onFilterClear = (emptyFilters: DataTableFilterMeta): void => {
		if (!tablePFSEvent && filteredRoles.length === 0) return

		if (tablePFSEvent) {
			const newEventData = { ...tablePFSEvent, filters: emptyFilters }
			setTablePFSEvent(newEventData)
			onFilter(newEventData)
		}

		setFilteredRoles([])
	}

	// LAZY TABLE FUNCTIONS
	const onFilter = (e: DataTablePFSEvent): void => {
		const formattedFilters = formatTableFilters(e)
		setUserRequestData({ ...userRequestData, ...formattedFilters })
	}

	const onPage = (e: DataTablePFSEvent): void => {
		if (e.page === userRequestData.page && e.rows === userRequestData.size) return
		setUserRequestData({ ...userRequestData, pageNumber: e.page, pageSize: e.rows })
	}

	const onSort = (e: DataTablePFSEvent): void => {
		const finalField = UserPropertyMap.get(e.sortField)
		const finalOrder = tableSortValueToSortDirection(e.sortOrder)
		setUserRequestData({
			...userRequestData,
			sortFields: finalField ? [finalField] : undefined,
			sortOrders: !!finalField && !!finalOrder ? [finalOrder] : undefined,
			// Return to first page
			pageNumber: 0,
		})
	}

	useEffect(() => {
		setUserRequestData({ ...userRequestData, roles: filteredRoles.map((r) => parseInt(r.value)) })
	}, [filteredRoles])

	const getFilterButtons = useCallback(
		() => (
			<>
				<RoleSelectTooltipButton
					buttonProps={{
						icon: faFilter,
						uiStyle: 'primereact',
						content: translate('ROLES.FILTER'),
					}}
					tooltipProps={{
						placement: 'right-end',
						showArrow: false,
						styles: { width: '300px' },
					}}
					roleSelectProps={{
						isMulti: true,
						noLabel: true,
						includeRoleTypes: internalUser
							? [RoleTypes.EXTERNAL_ROLE, RoleTypes.INTERNAL_ROLE]
							: [RoleTypes.EXTERNAL_ROLE],
						onChange: (e) => {
							setFilteredRoles((e as IUfinetSelectOption[]) || [])
						},
						value: filteredRoles,
						closeMenuOnSelect: true,
					}}
					behaviour="click"
					hideMethod="hide"
				/>
			</>
		),
		[translate, internalUser, filteredRoles]
	)

	const getHeaderButtons = useCallback(() => {
		return (
			<>
				{authPermissions.canDelete && (
					<MultipleDeleter
						setSelectedValues={setSelectedValues}
						selectedValues={selectedValues!}
						deletePayloadBaseName="users"
						deleteObjectKeys={['userType']}
						deleteEndpoint={_userService.deleteByIds}
						search={fetchAndStore}
					/>
				)}
				{authPermissions.canWrite && (
					<>
						{internalUser && (
							<UfinetButton
								className="me-3"
								icon={faPlus}
								onClick={() => {
									setUserId(undefined)
									showInternalUserModal()
								}}
								content={translate('USER.INTERNAL')}
							/>
						)}
						<UfinetButton
							className="me-3"
							icon={faPlus}
							onClick={() => {
								setUserId(undefined)
								showExternalUserModal()
							}}
							content={translate(internalUser ? 'USER.EXTERNAL' : 'USER.ADD')}
						/>
					</>
				)}
			</>
		)
	}, [
		authPermissions,
		selectedValues,
		_userService.deleteByIds,
		fetchAndStore,
		internalUser,
		translate,
		showInternalUserModal,
		showExternalUserModal,
	])

	return (
		<UfinetBox>
			<UfinetSectionBox>
				<Table
					ref={tableRef}
					dataKey="idAndType"
					selectedValues={selectedValues}
					onSelectionChange={authPermissions.canDelete ? onSelectionChange : undefined}
					cols={colsUser}
					content={users}
					filterButtons={getFilterButtons()}
					headerButtons={getHeaderButtons()}
					actionBodyTemplate={
						authPermissions.canUpdate
							? (user: UserFindResponse) =>
									getEditPencilBodyTemplate(user, () => {
										setSelectedValues([])
										setUserId(user.id)
										user.userType === UserTypesEnum.EXTERNAL_USER ? showExternalUserModal() : showInternalUserModal()
									})
							: undefined
					}
					emptyMessage={loadingRecords ? translate('LOADING_DOTS') : undefined}
					afterTablePFSEvent={setTablePFSEvent}
					onFilterClear={onFilterClear}
					lazySettings={
						paging && {
							...paging,
							loading: loadingRecords,
							onFilter,
							onPage,
							onSort,
						}
					}
				/>
			</UfinetSectionBox>
			<UfinetModal
				size="lg"
				show={internalUserModal}
				handleClose={hideInternalUserModal}
				title={translate(userId ? 'USER.INTERNAL.EDIT' : 'USER.INTERNAL.NEW')}
			>
				<InternalUserModal userId={userId} afterSubmit={hideInternalUserModal} onUserSubmitted={fetchAndStore} />
			</UfinetModal>
			<UfinetModal
				size="xl"
				show={externalUserModal}
				handleClose={hideExternalUserModal}
				title={translate(
					userId
						? internalUser
							? 'USER.EXTERNAL.EDIT'
							: 'USER.GENERIC.EDIT'
						: internalUser
						? 'USER.EXTERNAL.NEW'
						: 'USER.GENERIC.NEW'
				)}
			>
				<ExternalUserModal userId={userId} afterSubmit={hideExternalUserModal} onUserSubmitted={fetchAndStore} />
			</UfinetModal>
		</UfinetBox>
	)
}
export { UsersPage }
