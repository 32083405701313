import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'
import { RoleFindResponse } from 'modules/role/application/find/dto/RoleFindResponse'
import { rolesService } from 'modules/role/application/rolesService'
import { Role } from 'modules/role/domain/Role'
import { roleTypeMappings } from 'modules/role/domain/types/RoleTypeMappings'

import { FC, useCallback, useContext, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { getEditPencilBodyTemplate, MultipleDeleter, Table, UfinetButton, UfinetModal } from 'ufinet-web-components'
import { AuthContext, Authority, useAsync, useModal } from 'ufinet-web-functions'
import { resetSelectedRolePermission } from '../../store/role/rolePermissionSlice'
import { RoleModal } from './modal/EditRole'
import { rolesColsData } from './RolesColsData'

export const RolesList: FC = () => {
	const intl = useIntl()
	const [roles, setRoles] = useState<Role[]>()
	const [newModal, showNewModal, hideNewModal] = useModal()
	const [editModal, showEditModal, hideEditModal] = useModal()
	const [roleId, setRoleId] = useState<number>()
	const [selectedValues, setSelectedValues] = useState<Object[]>([])

	const dispatch = useDispatch()
	const authData = useContext(AuthContext)
	const _rolesService = useMemo(() => rolesService(authData, intl), [authData, intl])

	const authRoles = authData.userData?.authorities || []
	const permissions = Authority.getSecurityConfigPermissions(authRoles)

	const [loadingRecords, setLoadingRecords] = useState<boolean>(false)

	const getRoles = useCallback(async () => {
		setLoadingRecords(true)
		return await _rolesService.findAll()
	}, [_rolesService])

	const onRolesFetched = (roles: RoleFindResponse[]): void => {
		setRoles(roles)
	}

	const onRolesFailedToFetch = (): void => {
		toast.error(intl.formatMessage({ id: 'ROLES.FETCH.ERROR' }))
	}

	const runFinally = () => {
		setLoadingRecords(false)
	}

	const fetchAndStore = useAsync(
		{ asyncFn: getRoles, onSuccess: onRolesFetched, onFailure: onRolesFailedToFetch, runFinally: runFinally },
		[]
	)

	const onSelectionChange = (values: Object[]) => setSelectedValues(values)

	const headersButtons = useMemo(
		() => (
			<>
				{permissions.canDelete && (
					<MultipleDeleter
						setSelectedValues={setSelectedValues}
						selectedValues={selectedValues!}
						deleteEndpoint={_rolesService.deleteByIdsList}
						search={fetchAndStore}
					/>
				)}
				{permissions.canWrite && (
					<UfinetButton
						className="me-3"
						icon={faPlus}
						onClick={() => {
							showNewModal()
						}}
						content={intl.formatMessage({ id: 'NEW.REGISTER' })}
					/>
				)}
			</>
		),
		[
			_rolesService.deleteByIdsList,
			fetchAndStore,
			intl,
			permissions.canDelete,
			permissions.canWrite,
			selectedValues,
			showNewModal,
		]
	)

	const handleHideModal = () => {
		hideNewModal()
		hideEditModal()
		dispatch(resetSelectedRolePermission())
	}

	const onSaveRole = async () => {
		await fetchAndStore()
		handleHideModal()
		dispatch(resetSelectedRolePermission())
	}

	return (
		<>
			<Table
				selectedValues={selectedValues}
				onSelectionChange={permissions.canDelete ? onSelectionChange : undefined}
				cols={rolesColsData}
				content={roles?.map((role) => ({
					...role,
					type: intl.formatMessage({ id: roleTypeMappings.get(role.type) }),
				}))}
				headerButtons={headersButtons}
				actionBodyTemplate={
					permissions.canUpdate
						? (role: Role) =>
								getEditPencilBodyTemplate(role, () => {
									setRoleId(role.id)
									showEditModal()
								})
						: undefined
				}
				emptyMessage={loadingRecords ? intl.formatMessage({ id: 'LOADING_DOTS' }) : undefined}
			/>

			<UfinetModal
				size="xl"
				show={newModal}
				handleClose={handleHideModal}
				title={intl.formatMessage({ id: 'MODAL.NEW.REGISTER.TITLE' })}
				stickyHeader
			>
				<RoleModal onSaveRole={onSaveRole} />
			</UfinetModal>
			<UfinetModal
				size="xl"
				show={editModal}
				handleClose={handleHideModal}
				title={intl.formatMessage({ id: 'MODAL.EDIT.ROLE.TITLE' })}
			>
				<RoleModal id={roleId} onSaveRole={onSaveRole} />
			</UfinetModal>
		</>
	)
}
