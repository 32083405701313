import { UserFindPagedResponse } from 'modules/user/common/application/find/dto/UserFindResponse'
import { rest } from 'msw'

export const mockUsers: UserFindPagedResponse = { pagingData: undefined, users: [] }

export const userHandlers = [
	rest.get(/.*api\/user/, (req, res, ctx) => {
		return res(ctx.status(200), ctx.json(mockUsers))
	}),
]
