import { useFormik } from 'formik'
import { companyService } from 'modules/company/application/companiesService'
import { FC, useCallback, useContext, useRef } from 'react'
import { useIntl } from 'react-intl'
import { UfinetActionButton, UfinetActionButtonHandle, UfinetInput } from 'ufinet-web-components'
import { AuthContext, onFormikTextChanges } from 'ufinet-web-functions'
import * as Yup from 'yup'

type Props = {
	search: Function
	hideModal: Function
}
export const NewCompanyModalBody: FC<Props> = ({ hideModal, search }) => {
	const intl = useIntl()

	const authData = useContext(AuthContext)
	const _companyService = companyService(authData, intl)

	const actionButtonRef = useRef<UfinetActionButtonHandle>(null)
	const dataFormSchema = Yup.object().shape({
		name: Yup.string().required(intl.formatMessage({ id: 'ERROR.REQUIRED' })),
		description: Yup.string().required(intl.formatMessage({ id: 'ERROR.REQUIRED' })),
	})
	const sendData = (values: any) => {
		actionButtonRef.current?.changeActionStatus()
		_companyService
			.create({
				name: values.name,
				description: values.description,
				domain: values.domain,
				domain2: values.domain2,
				domain3: values.domain3,
			})
			.then(() => {
				search()
				hideModal()
				return values
			})
			.catch(console.error)
			.finally(() => actionButtonRef.current?.changeActionStatus())
	}
	const formik = useFormik({
		initialValues: {
			name: '',
			description: '',
			domain: '',
			domain2: '',
			domain3: '',
		},
		validationSchema: dataFormSchema,
		onSubmit: sendData,
		validateOnChange: false,
		validateOnBlur: false,
	})

	const onTextChange = useCallback(onFormikTextChanges, [])

	return (
		<form onSubmit={formik.handleSubmit} className="container p-15 h-100 d-flex flex-column justify-content-center">
			<div className="row pb-10">
				<UfinetInput
					requiredIcon
					error={formik.errors.name}
					type="text"
					labelTitle={intl.formatMessage({ id: 'NAME' })}
					tooltipTitle={intl.formatMessage({ id: 'COMPANY.NAME' })}
					className="col-6"
					onChange={onTextChange(formik, 'name')}
					value={formik.values.name}
				/>
				<UfinetInput
					requiredIcon
					error={formik.errors.description}
					type="text"
					labelTitle={intl.formatMessage({ id: 'DESCRIPTION' })}
					tooltipTitle={intl.formatMessage({ id: 'COMPANY.DESCRIPTION' })}
					className="col-6"
					onChange={onTextChange(formik, 'description')}
					value={formik.values.description}
				/>
			</div>
			<div className="row pb-10">
				<UfinetInput
					error={formik.errors.domain}
					type="text"
					labelTitle={intl.formatMessage({ id: 'DOMAIN' })}
					tooltipTitle={intl.formatMessage({ id: 'COMPANY.DOMAIN' })}
					className="col-4"
					onChange={onTextChange(formik, 'domain')}
					value={formik.values.domain}
				/>
				<UfinetInput
					error={formik.errors.domain2}
					type="text"
					labelTitle={intl.formatMessage({ id: 'DOMAIN_2' })}
					tooltipTitle={intl.formatMessage({ id: 'COMPANY.DOMAIN_2' })}
					className="col-4"
					onChange={onTextChange(formik, 'domain2')}
					value={formik.values.domain2}
				/>
				<UfinetInput
					error={formik.errors.domain3}
					type="text"
					labelTitle={intl.formatMessage({ id: 'DOMAIN_3' })}
					tooltipTitle={intl.formatMessage({ id: 'COMPANY.DOMAIN_3' })}
					className="col-4"
					onChange={onTextChange(formik, 'domain3')}
					value={formik.values.domain3}
				/>
			</div>
			<UfinetActionButton ref={actionButtonRef} className="mt-10" content={intl.formatMessage({ id: 'SAVE' })} />
		</form>
	)
}
