import { defaultOptionsFetch, FetchAPI, FetchOptions, IAuthData } from 'ufinet-web-functions'
import { UserOrganizationalInfoRepository } from '../domain/repository/UserOrganizationalInfoRepository'

const url = `${process.env.REACT_APP_URL_BACK_PORTAL}api/organization/user`

export const HttpUserOrganizationalInfoRepository: (authData: IAuthData) => UserOrganizationalInfoRepository = (
	authData
) => {
	const fetchOptions: FetchOptions = {
		...defaultOptionsFetch,
		notifyError: false,
		authenticationData: authData,
		sendApiKey: true,
	}

	return {
		findActiveDirectoryInfo: async (userOrganizationalInfoFindRequest, options?: FetchOptions) =>
			await FetchAPI.get_params(`${url}/azure-ad`, userOrganizationalInfoFindRequest, { ...fetchOptions, ...options }),
	}
}
