import { FC } from 'react'
import { I18nProvider, Ufineti18nProvider } from 'ufinet-web-components'
import { portalI18nMessages } from '../../../modules/i18n/domain/messages'

const PortalI18nProvider: FC = ({ children }) => {
	return (
		<Ufineti18nProvider>
			<I18nProvider messagesByLanguage={portalI18nMessages}>{children}</I18nProvider>
		</Ufineti18nProvider>
	)
}

export { PortalI18nProvider }
