import { IntlShape } from 'react-intl'
import { defaultOptionsFetch, FetchAPI, FetchOptions, IAuthData, successNotifications } from 'ufinet-web-functions'
import { UserDeleteRequest } from './delete/dto/UserDeleteRequest'
import { UserFindRequest } from './find/dto/UserFindRequest'
import { UserFindPagedResponse } from './find/dto/UserFindResponse'

const url = `${process.env.REACT_APP_URL_BACK_PORTAL}api/user`

export const userService = (authData: IAuthData, intl: IntlShape) => {
	const fetchOptions: FetchOptions = {
		...defaultOptionsFetch,
		authenticationData: authData,
	}

	return {
		findAll: async (): Promise<UserFindPagedResponse> => await FetchAPI.get(url, fetchOptions),

		find: async (params: UserFindRequest): Promise<UserFindPagedResponse> =>
			await FetchAPI.get_params(url, params, fetchOptions),

		deleteByIds: async (criteria: UserDeleteRequest): Promise<void> =>
			await FetchAPI.deleteWithBody(`${url}/delete`, criteria, {
				...fetchOptions,
				notification: successNotifications.deleteByIdsList(intl, {
					section: intl.formatMessage({ id: 'SECTION.USERS' }).toLowerCase(),
				}),
			}),
	}
}
