import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'
import { applicationService } from 'modules/apps/application/applicationService'
import { Application } from 'modules/apps/domain/Application'
import { FC, useCallback, useContext, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { PATH_MODULES } from 'routing/protected/PrivateRoutes'
import { MultipleDeleter, Table, UfinetBox, UfinetButton, UfinetModal, UfinetSectionBox } from 'ufinet-web-components'
import { AuthContext, Authority, useAsync, useModal, useTranslator } from 'ufinet-web-functions'
import { colsApps } from './AppsColsData'
import { NewAppModalBody } from './modal/NewAppModalBody'

export const AppsPage: FC = () => {
	const intl = useIntl()
	const translate = useTranslator()
	const [apps, setApps] = useState<Application[]>()
	const [selectedValues, setSelectedValues] = useState<Application[]>([])
	const [newModal, showNewModal, hideNewModal] = useModal()

	const authData = useContext(AuthContext)
	const _applicationService = useMemo(() => applicationService(authData, intl), [authData])

	const roles = authData.userData?.authorities || []
	const permissions = Authority.getApplicationConfigPermissions(roles)

	const [loadingRecords, setLoadingRecords] = useState<boolean>(false)

	const onSelectionChange = (values: Application[]) => {
		setSelectedValues(values)
	}

	const getApplications = useCallback(async () => {
		setLoadingRecords(true)
		return await _applicationService.findApps()
	}, [_applicationService])

	const onApplicationsFetched = (apps: Application[]): void => {
		setApps(apps)
	}

	const onApplicationsFailedToFetch = (): void => {
		toast.error(translate('APPLICATIONS.FETCH.ERROR'))
	}

	const runFinally = () => {
		setLoadingRecords(false)
	}

	const fetchAndStore = useAsync(
		{
			asyncFn: getApplications,
			onSuccess: onApplicationsFetched,
			onFailure: onApplicationsFailedToFetch,
			runFinally: runFinally,
		},
		[]
	)

	const onRowEditComplete = (data: Application) => {
		const { name, description, securityName } = data
		if (name !== '' && description !== '' && securityName !== '') {
			_applicationService
				.updateApp(data.id, { name, description, securityName })
				.then(fetchAndStore)
				.catch(console.error)
		}
	}

	const getHeadersButtons = () => (
		<>
			{permissions.canDelete && (
				<MultipleDeleter
					title={translate('APPLICATION.DELETE')}
					text={translate('APPLICATION.DELETE.CONFIRM')}
					setSelectedValues={setSelectedValues}
					selectedValues={selectedValues}
					deleteEndpoint={_applicationService.deleteByIdsList}
					search={fetchAndStore}
				/>
			)}
			{permissions.canWrite && (
				<UfinetButton
					className="me-3"
					icon={faPlus}
					onClick={() => {
						showNewModal()
					}}
					content={translate('NEW.REGISTER')}
				/>
			)}
		</>
	)

	const getActionBodyTemplate = (rowData: Application) => (
		<Link replace={false} to={`/${PATH_MODULES}/${rowData.id}/${rowData.name}`} className="p-2 span-color-blue">
			{translate('MODULES')}
		</Link>
	)

	return (
		<UfinetBox>
			<UfinetSectionBox>
				<Table
					selectedValues={selectedValues}
					onSelectionChange={permissions.canDelete ? onSelectionChange : undefined}
					onRowEditComplete={permissions.canUpdate ? onRowEditComplete : undefined}
					headerButtons={getHeadersButtons()}
					actionBodyTemplate={getActionBodyTemplate}
					content={apps}
					cols={colsApps}
					emptyMessage={loadingRecords ? translate('LOADING_DOTS') : undefined}
				/>
			</UfinetSectionBox>
			<UfinetModal size="lg" show={newModal} handleClose={hideNewModal} title={translate('MODAL.CREATE.APP.TITLE')}>
				<NewAppModalBody search={fetchAndStore} hideModal={hideNewModal} />
			</UfinetModal>
		</UfinetBox>
	)
}
