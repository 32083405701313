import { useQuery } from '@tanstack/react-query'
import { UserOrganizationalFlowRepository } from '../../domain/repository/UserOrganizationalFlowRepository'
import { UserOrganizationalFlowFindResponse } from './dto/UserOrganizationalFlowFindResponse'

export const useUserOrganizationalFlowFindAllQuery = (
	userOrganizationalFlowRepository: UserOrganizationalFlowRepository,
	{
		enabled = true,
		onSuccess,
		onError,
	}: {
		enabled?: boolean
		onSuccess?: (userOrganizationalFlow: UserOrganizationalFlowFindResponse) => void
		onError?: (error: unknown) => void
	} = {}
) => {
	return useQuery({
		queryKey: ['user-organizational-flow-find-all'],
		queryFn: userOrganizationalFlowRepository.findAll,
		enabled,
		onSuccess,
		onError,
	})
}
