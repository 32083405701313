import { i18nMessages } from 'ufinet-web-components'
import portalEnMessages from './en.json'
import portalEsMessages from './es.json'
import portalPtMessages from './pt.json'

export const portalI18nMessages: i18nMessages = {
	en: {
		...portalEnMessages,
	},
	es: {
		...portalEsMessages,
	},
	pt: {
		...portalPtMessages,
	},
}
