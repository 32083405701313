import { UserOrganization } from 'components/organization/UserOrganization'
import { FC } from 'react'
import { UfinetBox, UfinetSectionBox } from 'ufinet-web-components'

const UsersOrganizationPage: FC = () => {
	return (
		<UfinetBox>
			<UfinetSectionBox>
				<UserOrganization />
			</UfinetSectionBox>
		</UfinetBox>
	)
}

export { UsersOrganizationPage }
