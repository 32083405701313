import { IntlShape } from 'react-intl'
import {
	defaultOptionsFetch,
	FetchAPI,
	FetchOptions,
	IAuthData,
	IMultipleDeleteRequest,
	successNotifications,
} from 'ufinet-web-functions'
import { ApplicationModuleFindResponse } from './find/dto/ApplicationModuleFindResponse'
import { ApplicationModuleSaveRequest } from './save/dto/ApplicationModuleSaveRequest'
import { ApplicationModuleUpdateRequest } from './update/dto/ApplicationModuleUpdateRequest'

const url = `${process.env.REACT_APP_URL_BACK_PORTAL}api/module`

export const moduleService = (authData: IAuthData, intl: IntlShape) => {
	const fetchOptions: FetchOptions = {
		...defaultOptionsFetch,
		authenticationData: authData,
	}

	return {
		find: async (id: number): Promise<ApplicationModuleFindResponse[]> =>
			await FetchAPI.get(`${url}/${id}`, fetchOptions),
		create: async (id: number, request: ApplicationModuleSaveRequest): Promise<any> =>
			await FetchAPI.post(`${url}/${id}`, request, {
				...fetchOptions,
				notification: successNotifications.createRegistry(intl, request.name),
			}),
		update: async (appId: number, moduleId: number, request: ApplicationModuleUpdateRequest): Promise<any> =>
			await FetchAPI.put(`${url}/${appId}/${moduleId}`, request, {
				...fetchOptions,
				notification: successNotifications.updateRegistry(intl, request.name),
			}),
		delete: async (id: number): Promise<any> =>
			await FetchAPI.delete(`${url}/${id}`, {
				...fetchOptions,
				notification: successNotifications.deleteById(intl, {
					id,
					section: intl.formatMessage({ id: 'SECTION.APPS.MODULES' }).toLowerCase(),
				}),
			}),
		deleteByIdsList: async (appId: number, moduleIds: IMultipleDeleteRequest<number>): Promise<any> =>
			await FetchAPI.deleteWithBody(`${url}/${appId}`, moduleIds, {
				...fetchOptions,
				notification: successNotifications.deleteByIdsList(intl, {
					section: intl.formatMessage({ id: 'SECTION.APPS.MODULE' }).toLowerCase(),
				}),
			}),
	}
}
