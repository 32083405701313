import { useFormik } from 'formik'
import { moduleService } from 'modules/apps/modules/application/modulesService'
import { FC, useCallback, useContext, useRef } from 'react'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import { UfinetActionButton, UfinetActionButtonHandle, UfinetInput } from 'ufinet-web-components'
import { AuthContext, onFormikTextChanges } from 'ufinet-web-functions'
import * as Yup from 'yup'

type Props = {
	search: Function
	hideModal: Function
}
export const NewModuleModalBody: FC<Props> = ({ hideModal, search }) => {
	const intl = useIntl()
	const { appId = '' } = useParams()
	const actionButtonRef = useRef<UfinetActionButtonHandle>(null)

	const authData = useContext(AuthContext)
	const _moduleService = moduleService(authData, intl)

	const dataFormSchema = Yup.object().shape({
		name: Yup.string().required(intl.formatMessage({ id: 'ERROR.REQUIRED' })),
		description: Yup.string().required(intl.formatMessage({ id: 'ERROR.REQUIRED' })),
		securityName: Yup.string().required(intl.formatMessage({ id: 'ERROR.REQUIRED' })),
	})
	const sendData = (values: any) => {
		actionButtonRef.current?.changeActionStatus()
		const { name, description, securityName } = values
		_moduleService
			.create(parseInt(appId), { name, description, securityName })
			.then(() => {
				search()
				hideModal()
				return values
			})
			.catch(console.error)
			.finally(() => actionButtonRef.current?.changeActionStatus())
	}
	const formik = useFormik({
		initialValues: {
			name: '',
			description: '',
			securityName: '',
		},
		validationSchema: dataFormSchema,
		onSubmit: sendData,
		validateOnChange: false,
		validateOnBlur: false,
	})

	const onTextChange = useCallback(onFormikTextChanges, [])

	return (
		<form onSubmit={formik.handleSubmit} className="container p-15 h-100 d-flex flex-column justify-content-center">
			<div className="row pb-10">
				<UfinetInput
					requiredIcon
					error={formik.errors.name}
					type="text"
					labelTitle={intl.formatMessage({ id: 'NAME' })}
					tooltipTitle={intl.formatMessage({ id: 'MODULE.NAME' })}
					className="col-6"
					onChange={onTextChange(formik, 'name')}
					value={formik.values.name}
				/>
				<UfinetInput
					requiredIcon
					error={formik.errors.securityName}
					type="text"
					labelTitle={intl.formatMessage({ id: 'APPLICATION.CODE' })}
					tooltipTitle={intl.formatMessage({ id: 'APPLICATION.CODE.TOOLTIP' })}
					className="col-6"
					onChange={onTextChange(formik, 'securityName')}
					value={formik.values.securityName}
				/>
				<UfinetInput
					requiredIcon
					error={formik.errors.description}
					type="text"
					labelTitle={intl.formatMessage({ id: 'DESCRIPTION' })}
					tooltipTitle={intl.formatMessage({ id: 'MODULE.DESCRIPTION' })}
					className="col-6"
					onChange={onTextChange(formik, 'description')}
					value={formik.values.description}
				/>
			</div>
			<UfinetActionButton ref={actionButtonRef} className="mt-10" content={intl.formatMessage({ id: 'SAVE' })} />
		</form>
	)
}
