import { IntlShape } from 'react-intl'
import {
	defaultOptionsFetch,
	FetchAPI,
	FetchOptions,
	IAuthData,
	IMultipleDeleteRequest,
	successNotifications,
} from 'ufinet-web-functions'
import { RoleFindDetailResponse, RoleFindResponse } from './find/dto/RoleFindResponse'
import { RoleSaveRequest } from './save/dto/RoleSaveRequest'
import { RoleUpdateRequest } from './update/dto/RoleUpdateRequest'

const url = `${process.env.REACT_APP_URL_BACK_PORTAL}api/role`
export const rolesService = (authData: IAuthData, intl: IntlShape) => {
	const fetchOptions: FetchOptions = {
		...defaultOptionsFetch,
		authenticationData: authData,
	}

	return {
		findAll: async (): Promise<RoleFindResponse[]> => await FetchAPI.get(`${url}`, fetchOptions),
		findInternal: async (): Promise<RoleFindResponse[]> => await FetchAPI.get(`${url}/internal`, fetchOptions),
		findExternal: async (): Promise<RoleFindResponse[]> => await FetchAPI.get(`${url}/external`, fetchOptions),
		getEmpty: async (): Promise<RoleFindDetailResponse> => await FetchAPI.get(`${url}/new`, fetchOptions),
		get: async (id: number): Promise<RoleFindDetailResponse> => await FetchAPI.get(`${url}/${id}`, fetchOptions),
		create: async (request: RoleSaveRequest): Promise<any> =>
			await FetchAPI.post(`${url}`, request, {
				...fetchOptions,
				notification: successNotifications.createRegistry(intl, request.name),
			}),
		update: async (id: number, request: RoleUpdateRequest): Promise<any> =>
			await FetchAPI.put(`${url}/${id}`, request, {
				...fetchOptions,
				notification: successNotifications.updateRegistry(intl, request.name),
			}),
		delete: async (id: number): Promise<any> =>
			await FetchAPI.delete(`${url}/${id}`, {
				...fetchOptions,
				notification: successNotifications.deleteById(intl, {
					id,
					section: intl.formatMessage({ id: 'SECTION.ROLES' }).toLowerCase(),
				}),
			}),
		deleteByIdsList: async (ids: IMultipleDeleteRequest<number>): Promise<any> =>
			await FetchAPI.deleteWithBody(`${url}`, ids, {
				...fetchOptions,
				notification: successNotifications.deleteByIdsList(intl, {
					section: intl.formatMessage({ id: 'SECTION.ROLES' }).toLowerCase(),
				}),
			}),
	}
}
