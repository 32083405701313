import { FC, useCallback, useMemo } from 'react'
import { CheckBoxGroupProps, UfinetCheckBoxGroup, UfinetCheckBoxOption } from 'ufinet-web-components'
import { useTranslator } from 'ufinet-web-functions'

type AdminPermissionsCheckBoxProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> &
	Pick<CheckBoxGroupProps, 'titleClassName' | 'error' | 'disabled'> & {
		onChange?: (newValue: boolean) => void
		value?: boolean
		disabled?: boolean
	}

export const CREATE_ADMIN_PERMISSIONS = 'CREATE_ADMIN_PERMISSIONS'

const AdminPermissionsCheckBox: FC<AdminPermissionsCheckBoxProps> = ({
	className,
	titleClassName,
	onChange,
	error,
	value,
	disabled = false,
}) => {
	const translate = useTranslator()
	const adminPermissionsOptions = useMemo(
		() => [
			{
				label: translate(CREATE_ADMIN_PERMISSIONS),
				value: CREATE_ADMIN_PERMISSIONS,
				name: CREATE_ADMIN_PERMISSIONS,
			},
		],
		[translate]
	)

	const onInnerCheckboxChange = useCallback(
		(newValue: UfinetCheckBoxOption[]) => {
			const isChecked = !!newValue.find((it) => it.value === adminPermissionsOptions[0].value)
			onChange?.(isChecked)
		},
		[adminPermissionsOptions, onChange]
	)

	return (
		<div className={className}>
			<UfinetCheckBoxGroup
				name={CREATE_ADMIN_PERMISSIONS}
				options={adminPermissionsOptions}
				error={error}
				titleClassName={titleClassName}
				disabled={disabled}
				selection={value ? [adminPermissionsOptions[0].value] : []}
				onChange={onInnerCheckboxChange}
			/>
		</div>
	)
}

export { AdminPermissionsCheckBox }
