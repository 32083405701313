import { UserFindResponse } from 'modules/user/common/application/find/dto/UserFindResponse'
import { ColData, DivWrapped } from 'ufinet-web-components'
import { convertToLocalTZ, TranslatorFunction, userTypeMappings, UserTypesEnum } from 'ufinet-web-functions'

export const usersTableColumns = (translate: TranslatorFunction, options: { internalUser: boolean }): ColData[] => [
	{
		field: 'fullName',
		header: 'COL.USER.NAME',
		width: '250px',
		nonFilterable: true,
		editable: true,
		body: (row: UserFindResponse) => row.fullName || '-',
	},
	{
		field: 'userType',
		hidden: !options.internalUser,
		header: 'COL.USER.TYPE',
		width: '150px',
		nonFilterable: false,
		filterOptions: [
			{
				label: translate(userTypeMappings.get(UserTypesEnum.INTERNAL_USER)!),
				value: UserTypesEnum.INTERNAL_USER,
			},
			{
				label: translate(userTypeMappings.get(UserTypesEnum.EXTERNAL_USER)!),
				value: UserTypesEnum.EXTERNAL_USER,
			},
		],
		nonSearchable: true,
		nonSortable: true,
		editable: true,
		body: (row: UserFindResponse) => translate(userTypeMappings.get(row.userType)!),
	},
	{
		field: 'email',
		header: 'COL.USER.EMAIL',
		width: '220px',
		nonFilterable: true,
		editable: true,
		body: (row: UserFindResponse) => <DivWrapped className="ufinet-table-cell" input={row.email} separator="@" />,
	},
	{
		field: 'lastModifiedBy',
		header: 'COL.ACT_BY',
		width: '220px',
		hidden: !options.internalUser,
		nonFilterable: true,
		nonSearchable: true,
		body: (row: UserFindResponse) => (
			<DivWrapped className="ufinet-table-cell" input={row.lastUpdatedBy} separator="@" />
		),
	},
	{
		field: 'lastUpdatedAt',
		header: 'COL.ACT_AT',
		width: '220px',
		nonFilterable: true,
		nonSearchable: true,
		body: (row: UserFindResponse) => convertToLocalTZ(row.lastUpdatedAt),
	},
]
