import { IRoleSelectProps, RoleSelect } from 'components/selects/RoleSelect'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { IUfinetSelectOption, TooltipButton, TooltipButtonProps } from 'ufinet-web-components'
import { DOMEventFireCondition } from 'ufinet-web-functions'
import { v4 as uuidv4 } from 'uuid'

export type RoleSelectTooltipButtonProps = TooltipButtonProps & {
	roleSelectProps?: IRoleSelectProps
}

const RoleSelectTooltipButton: FC<RoleSelectTooltipButtonProps> = ({
	tooltipProps,
	buttonProps,
	roleSelectProps,
	...rest
}) => {
	const selectorId = useMemo(() => uuidv4(), [])

	const [active, setActive] = useState(false)

	useEffect(() => {
		roleSelectProps?.value && setActive((roleSelectProps.value as IUfinetSelectOption[]).length > 0)
	}, [roleSelectProps?.value])

	const onRoleSelectChange = (roles: IUfinetSelectOption[]) => {
		roleSelectProps?.onChange?.(roles)
		setActive(roles.length > 0)
	}

	const clickedOutOfSelector = useCallback<DOMEventFireCondition>(
		(element, event) => {
			event?.stopPropagation()
			if (!event || !event.target) return true

			const target = event.target as Node
			if (!document.contains(target)) return false

			document.querySelectorAll(`[id*="${selectorId}"]`).forEach((el) => {
				if (el.contains(target)) {
					return false
				}
			})
			return true
		},
		[selectorId]
	)

	return (
		<TooltipButton
			{...rest}
			buttonProps={buttonProps}
			tooltipProps={tooltipProps}
			hideConditions={[clickedOutOfSelector]}
			active={active || undefined}
		>
			<RoleSelect
				{...roleSelectProps}
				id={selectorId}
				onChange={(e) => onRoleSelectChange(e as IUfinetSelectOption[])}
			/>
		</TooltipButton>
	)
}

export { RoleSelectTooltipButton }
