import { RolesList } from 'components/roles/RolesList'
import { FC } from 'react'
import { UfinetBox, UfinetSectionBox } from 'ufinet-web-components'

export const RolesPage: FC = () => {
	return (
		<UfinetBox>
			<UfinetSectionBox>
				<RolesList />
			</UfinetSectionBox>
		</UfinetBox>
	)
}
