import { useQuery } from '@tanstack/react-query'
import { ResponseError } from 'ufinet-web-functions'
import { UserOrganizationalInfoRepository } from '../../domain/repository/UserOrganizationalInfoRepository'
import { UserActiveDirectoryInfo } from '../../domain/UserActiveDirectoryInfo'
import { UserOrganizationalInfoFindRequest } from './dto/UserOrganizationalInfoFindRequest'
import { UserActiveDirectoryInfoFindResponse } from './dto/UserOrganizationalInfoFindResponse'

export const useUserActiveDirectoryInfoFindQuery = (
	userOrganizationalInfoRepository: UserOrganizationalInfoRepository,
	userOrganizationalInfoFindRequest: UserOrganizationalInfoFindRequest,
	{
		enabled = false,
		onSuccess,
		onError,
	}: {
		enabled?: boolean
		onSuccess?: (userActiveDirectoryInfo: UserActiveDirectoryInfo) => void
		onError?: (error: ResponseError) => void
	} = {}
) => {
	return useQuery({
		queryKey: ['user-active-directory-info-find', userOrganizationalInfoFindRequest],
		queryFn: () => userOrganizationalInfoRepository.findActiveDirectoryInfo(userOrganizationalInfoFindRequest),
		select: (userActiveDirectoryInfoFindResponse: UserActiveDirectoryInfoFindResponse) =>
			userActiveDirectoryInfoFindResponse as UserActiveDirectoryInfo,
		enabled,
		onSuccess,
		onError,
		cacheTime: 0,
	})
}
