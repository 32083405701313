import { Company } from 'modules/company/domain/Company'
import { ColData, DivWrapped } from 'ufinet-web-components'
import { convertToLocalTZ } from 'ufinet-web-functions'

export const colsCompanies: ColData[] = [
	{ field: 'name', header: 'COL.COMPANY.NAME', width: '250px', nonFilterable: false, editable: true },
	{ field: 'description', header: 'COL.COMPANY.DESCRIPTION', width: '250px', nonFilterable: false, editable: true },
	{ field: 'domain', header: 'COL.COMPANY.DOMAIN', width: '190px', nonFilterable: false, editable: true },
	{ field: 'domain2', header: 'COL.COMPANY.DOMAIN2', width: '190px', nonFilterable: false, editable: true },
	{ field: 'domain3', header: 'COL.COMPANY.DOMAIN3', width: '190px', nonFilterable: false, editable: true },
	{
		field: 'lastModifiedBy',
		header: 'COL.ACT_BY',
		width: '250px',
		nonFilterable: true,
		body: (row: Company) => <DivWrapped className="ufinet-table-cell" input={row.lastModifiedBy} separator="@" />,
	},
	{
		field: 'lastModifiedAt',
		header: 'COL.ACT_AT',
		width: '220px',
		nonFilterable: true,
		body: (row: Company) => convertToLocalTZ(row.lastModifiedAt),
	},
]
