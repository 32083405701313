import { ColData } from 'ufinet-web-components'
import { EditRoleButton } from './EditRoleButton'

const getBody = (rowData: any, options: any) => <EditRoleButton rowData={rowData} options={options} />
export const rolesModulesColsData: ColData[] = [
	{ field: 'moduleName', header: 'COL.NAME', width: '180px', nonFilterable: true },
	{ field: 'permissions.read', header: 'COL.READ', width: '100px', nonFilterable: true, body: getBody },
	{ field: 'permissions.write', header: 'COL.CREATE', width: '100px', nonFilterable: true, body: getBody },
	{ field: 'permissions.update', header: 'COL.MODIFY', width: '100px', nonFilterable: true, body: getBody },
	{ field: 'permissions.delete', header: 'COL.DELETE', width: '100px', nonFilterable: true, body: getBody },
	{ field: 'permissions.export', header: 'COL.EXPORT', width: '100px', nonFilterable: true, body: getBody },
	{ field: 'permissions.admin', header: 'COL.ADMIN', width: '100px', nonFilterable: true, body: getBody },
]
