import { RoleTypes } from 'modules/role/domain/types/RoleTypes'
import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { UfinetCheckbox } from 'ufinet-web-components'

import { setSelectedRolePermissionType } from '../../../store/role/rolePermissionSlice'
import { RootState } from '../../../store/store'

export const EditRoleRoleType: FC = () => {
	const intl = useIntl()

	const stateRolePermissionsType = useSelector((state: RootState) => state.rolePermission.selectedRolePermission.type)
	const [internalRole, setInternalRole] = useState<boolean>()
	const [externalRole, setExternalRole] = useState<boolean>()

	const dispatch = useDispatch()

	useEffect(() => {
		if (stateRolePermissionsType) {
			if (stateRolePermissionsType === RoleTypes.INTERNAL_ROLE) setInternalRole(true)
			else if (stateRolePermissionsType === RoleTypes.EXTERNAL_ROLE) setExternalRole(true)
		}
	}, [stateRolePermissionsType])

	const setInternalRol = (event: any) => {
		setExternalRole(!event.target.checked)
		setInternalRole(event.target.checked)
		const roleType: RoleTypes = event.target.checked ? RoleTypes.INTERNAL_ROLE : RoleTypes.EXTERNAL_ROLE
		dispatch(setSelectedRolePermissionType(roleType))
	}
	const setExternalRol = (event: any) => {
		setExternalRole(event.target.checked)
		setInternalRole(!event.target.checked)
		const roleType: RoleTypes = event.target.checked ? RoleTypes.EXTERNAL_ROLE : RoleTypes.INTERNAL_ROLE
		dispatch(setSelectedRolePermissionType(roleType))
	}
	return (
		<div className="d-flex">
			<UfinetCheckbox
				className="pe-6"
				title={intl.formatMessage({ id: 'ROLE.INTERNAL.NAME.DETAIL' })}
				checked={internalRole}
				onChange={setInternalRol}
			/>
			<UfinetCheckbox
				title={intl.formatMessage({ id: 'ROLE.EXTERNAL.NAME.DETAIL' })}
				checked={externalRole}
				onChange={setExternalRol}
			/>
		</div>
	)
}
