import { useMutation } from '@tanstack/react-query'
import { ResponseError } from 'ufinet-web-functions'
import { UserOrganizationalFlowRepository } from '../../domain/repository/UserOrganizationalFlowRepository'
import { UserOrganizationalFlowUpdateRequest } from './dto/UserOrganizationalFlowUpdateRequest'

export const useUserOrganizationalFlowUpdateQuery = (
	userOrganizationalFlowRepository: UserOrganizationalFlowRepository,
	userOrganizationalFlowUpdateRequest: UserOrganizationalFlowUpdateRequest,
	{
		onSuccess,
		onError,
	}: {
		onSuccess?: () => void
		onError?: (error: ResponseError) => void
	} = {}
) => {
	return useMutation({
		mutationKey: ['user-organizational-flow-update', userOrganizationalFlowUpdateRequest],
		mutationFn: async () => {
			return await userOrganizationalFlowRepository.update(userOrganizationalFlowUpdateRequest)
		},
		onSuccess,
		onError,
	})
}
