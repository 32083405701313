import { useFormik } from 'formik'
import { RoleFindDetailResponse } from 'modules/role/application/find/dto/RoleFindResponse'
import { rolesService } from 'modules/role/application/rolesService'
import { RolePermissionsByApplication } from 'modules/role/domain/permission/RolePermissionsByApplication'
import { FC, useCallback, useContext, useMemo, useRef } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { Loading, Table, UfinetActionButton, UfinetActionButtonHandle, UfinetInput } from 'ufinet-web-components'
import { AuthContext, onFormikTextChanges, useAsync, useLoading } from 'ufinet-web-functions'
import * as Yup from 'yup'

import { setSelectedRolePermission } from '../../../store/role/rolePermissionSlice'
import { RootState } from '../../../store/store'
import { EditRoleRoleType } from './EditRoleRoleType'
import { rolesModulesColsData } from './RolesModulesColsData'

type RoleModalProps = {
	id?: number
	onSaveRole: Function
}
export const RoleModal: FC<RoleModalProps> = ({ id, onSaveRole }) => {
	const intl = useIntl()
	const buttonRef = useRef<UfinetActionButtonHandle>(null)
	const stateRolePermissions = useSelector((state: RootState) => state.rolePermission.selectedRolePermission)
	const dispatch = useDispatch()
	const onTextChange = useCallback(onFormikTextChanges, [])

	const authData = useContext(AuthContext)
	const _rolesService = useMemo(() => rolesService(authData, intl), [authData, intl])

	const { loading, startLoading, stopLoading } = useLoading()

	const saveForm = async (value: { name: string }) => {
		buttonRef.current?.changeActionStatus()
		startLoading()
		try {
			if (stateRolePermissions.id) {
				await _rolesService
					.update(stateRolePermissions.id, {
						name: value.name,
						type: stateRolePermissions.type,
						permissions: stateRolePermissions.permissions,
					})
					.catch(console.error)
			} else {
				await _rolesService
					.create({
						name: value.name,
						type: stateRolePermissions.type,
						permissions: stateRolePermissions.permissions,
					})
					.catch(console.error)
			}
		} catch (e) {
			console.error(e)
		} finally {
			stopLoading()
		}

		onSaveRole()
		buttonRef.current?.changeActionStatus()
	}

	const dataFormSchema = Yup.object().shape({
		name: Yup.string().required(intl.formatMessage({ id: 'ERROR.REQUIRED' })),
	})

	const formik = useFormik({
		initialValues: {
			name: '',
		},
		validationSchema: dataFormSchema,
		onSubmit: saveForm,
		validateOnChange: false,
		validateOnBlur: false,
	})

	const getRole = useCallback(async () => {
		startLoading()
		return id !== undefined ? _rolesService.get(id) : _rolesService.getEmpty()
	}, [_rolesService, id, startLoading])

	const onRoleFetched = useCallback(
		async (role: RoleFindDetailResponse) => {
			if (role.name) formik.setFieldValue('name', role.name)
			dispatch(setSelectedRolePermission(role))
			return role
		},
		[dispatch, formik]
	)

	const onRoleFailedToFetch = useCallback(async () => {
		toast.error(intl.formatMessage({ id: 'ROLE.FETCH.ERROR' }))
		onSaveRole()
	}, [intl, onSaveRole])

	// --------------------------

	useAsync(
		{
			asyncFn: getRole,
			onSuccess: onRoleFetched,
			onFailure: onRoleFailedToFetch,
			runFinally: stopLoading,
		},
		[]
	)

	return (
		<form
			onSubmit={formik.handleSubmit}
			className={`d-flex flex-column justify-content-center p-0 m-0 ${loading ? 'form-disabled' : ''}`}
		>
			<div className="d-flex flex-column">
				<div className="d-flex pb-8 justify-content-between">
					<UfinetInput
						requiredIcon
						error={formik.errors.name}
						labelTitle={intl.formatMessage({ id: 'NAME' })}
						tooltipTitle={intl.formatMessage({ id: 'ROLE.NAME' })}
						value={formik.values.name}
						onChange={onTextChange(formik, 'name')}
						type="text"
					/>
					<EditRoleRoleType />
				</div>

				{stateRolePermissions?.permissions.map((permission: RolePermissionsByApplication, index: number) => (
					<div key={`editRole-${index}`} className="pb-12">
						<h5 className="text-gray-400 fw-bold fs-5">{permission.applicationName}</h5>
						<Table cols={rolesModulesColsData} content={permission.permissions} />
					</div>
				))}
				<UfinetActionButton ref={buttonRef} content={intl.formatMessage({ id: 'SAVE' })} />
			</div>
			{loading && <Loading />}
		</form>
	)
}
