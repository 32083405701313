import { defaultOptionsFetch, FetchAPI, FetchOptions, IAuthData } from 'ufinet-web-functions'
import { UserOrganizationalFlowRepository } from '../domain/repository/UserOrganizationalFlowRepository'

const orgranizationFlowUrl = `${process.env.REACT_APP_URL_BACK_PORTAL}api/organization/chart`
const internalUsersFlowUrl = `${process.env.REACT_APP_URL_BACK_PORTAL}api/user/internal/organization`

export const HttpUserOrganizationalFlowRepository: (authData: IAuthData) => UserOrganizationalFlowRepository = (
	authData
) => {
	const fetchOptions: FetchOptions = {
		...defaultOptionsFetch,
		notifyError: false,
		authenticationData: authData,
		sendApiKey: true,
	}

	return {
		findAll: async (options) => await FetchAPI.get(orgranizationFlowUrl, { ...fetchOptions, ...options }),

		update: async (request, options) =>
			await FetchAPI.post(internalUsersFlowUrl, request, {
				...fetchOptions,
				...options,
			}),
	}
}
