import { RoleTypes } from 'modules/role/domain/types/RoleTypes'
import { IdAndNameAndType } from 'modules/user/common/domain/IdAndNameAndType'
import { IUfinetSelectOption } from 'ufinet-web-components'
import { IdAndName } from 'ufinet-web-functions'

export const optionToRole = (option: IUfinetSelectOption): IdAndName<number> & { type: RoleTypes } =>
	({
		id: +option.value,
		name: option.label,
		type: option.type,
	} as IdAndName<number> & { type: RoleTypes })

export const roleToOption = (role: IdAndNameAndType<number, RoleTypes>): IUfinetSelectOption =>
	({
		label: role.name,
		value: role.id.toString(),
		type: role.type,
	} as IUfinetSelectOption)
