import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'
import { applicationService } from 'modules/apps/application/applicationService'
import { Application } from 'modules/apps/domain/Application'
import { ApplicationModuleFindResponse } from 'modules/apps/modules/application/find/dto/ApplicationModuleFindResponse'
import { moduleService } from 'modules/apps/modules/application/modulesService'
import { ApplicationModule } from 'modules/apps/modules/domain/ApplicationModule'

import { FC, useContext, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import { MultipleDeleter, Table, UfinetBox, UfinetButton, UfinetModal, UfinetSectionBox } from 'ufinet-web-components'
import { AuthContext, Authority, convertToLocalTZ, useModal } from 'ufinet-web-functions'
import { colsModule } from './ModulesColsData'
import { NewModuleModalBody } from './NewModuleModalBody'

const ModulesPage: FC = () => {
	const intl = useIntl()
	const [selectedValues, setSelectedValues] = useState<ApplicationModule[]>([])
	const [newModal, showNewModal, hideNewModal] = useModal()
	const [modules, setModules] = useState<ApplicationModule[]>([])
	const [app, setApp] = useState<Application>()
	const { appId = '', appName } = useParams()

	const authData = useContext(AuthContext)
	const _applicationService = useMemo(() => applicationService(authData, intl), [authData, intl])
	const _moduleService = useMemo(() => moduleService(authData, intl), [authData, intl])

	const roles = authData.userData?.authorities || []
	const permissions = Authority.getApplicationConfigPermissions(roles)

	const searchModules = () => {
		_moduleService
			.find(parseInt(appId))
			.then((result: ApplicationModuleFindResponse[]) =>
				setModules(
					result.map((rowData: any) => ({
						...rowData,
						lastModifiedAt: convertToLocalTZ(rowData.lastModifiedAt),
					}))
				)
			)
			.catch(console.error)
	}

	const getAppDescription = () => {
		_applicationService
			.findAppById(parseInt(appId))
			.then((result: Application) => setApp(result))
			.catch(console.error)
	}

	useEffect(() => searchModules(), [appId])
	useEffect(() => getAppDescription(), [appId])

	const onSelectionChange = (values: ApplicationModule[]) => setSelectedValues(values)

	const getHeadersButtons = () => {
		return (
			<>
				{permissions.canDelete && (
					<MultipleDeleter
						title={intl.formatMessage({ id: 'MODULE.DELETE' })}
						setSelectedValues={setSelectedValues}
						selectedValues={selectedValues}
						deleteEndpoint={_moduleService.deleteByIdsList}
						deleteUrlParameters={[appId]}
						search={searchModules}
					/>
				)}
				{permissions.canWrite && (
					<UfinetButton
						className="me-3"
						icon={faPlus}
						onClick={() => showNewModal()}
						content={intl.formatMessage({ id: 'NEW.REGISTER' })}
					/>
				)}
			</>
		)
	}

	const onRowEditComplete = (data: ApplicationModule) => {
		const { name, description, securityName } = data
		if (name !== '' && description !== '' && securityName !== '') {
			_moduleService
				.update(parseInt(appId), data.id, { name, description, securityName })
				.then(() => searchModules())
				.catch(console.error)
		}
	}

	return (
		<UfinetBox>
			<UfinetSectionBox className="w-100  mb-3">
				<h2 className="fs-3 fw-bolder text-dark">{appName}</h2>
				<h5 className="text-gray-400 fw-bold fs-5">{app?.description}</h5>
			</UfinetSectionBox>
			<UfinetSectionBox>
				<Table
					selectedValues={selectedValues}
					onSelectionChange={permissions.canDelete ? onSelectionChange : undefined}
					onRowEditComplete={permissions.canUpdate ? onRowEditComplete : undefined}
					cols={colsModule}
					content={modules}
					headerButtons={getHeadersButtons()}
				/>
				<UfinetModal
					size="lg"
					show={newModal}
					handleClose={hideNewModal}
					title={intl.formatMessage({ id: 'MODAL.NEW.REGISTER.TITLE' })}
				>
					<NewModuleModalBody search={searchModules} hideModal={hideNewModal} />
				</UfinetModal>
			</UfinetSectionBox>
		</UfinetBox>
	)
}

export { ModulesPage }
