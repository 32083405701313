import { RoleFindResponse } from 'modules/role/application/find/dto/RoleFindResponse'
import { rolesService } from 'modules/role/application/rolesService'
import { Role } from 'modules/role/domain/Role'
import { roleTypeMappings } from 'modules/role/domain/types/RoleTypeMappings'
import { RoleTypes } from 'modules/role/domain/types/RoleTypes'
import { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import {
	ISelectBaseInterface,
	IUfinetGroupedOption,
	IUfinetSelectOption,
	UfinetSelect,
	UfinetSelectHandle,
	UfinetSelectOptionValue,
} from 'ufinet-web-components'
import { AuthContext, useAsync, useTranslator } from 'ufinet-web-functions'

export type RoleSelectHandle = UfinetSelectHandle & {}
export interface IRoleSelectProps extends ISelectBaseInterface {
	includeRoleTypes?: RoleTypes[]
	unclearable?: boolean
}

const RoleSelect = forwardRef<RoleSelectHandle, IRoleSelectProps>(
	({ unclearable, includeRoleTypes = [RoleTypes.INTERNAL_ROLE, RoleTypes.EXTERNAL_ROLE], ...props }, ref) => {
		const selectRef = useRef<UfinetSelectHandle>(null)

		const intl = useIntl()
		const translate = useTranslator()
		const authData = useContext(AuthContext)

		const _rolesService = useMemo(() => rolesService(authData, intl), [authData, intl])
		const includeInternalRoles = includeRoleTypes.includes(RoleTypes.INTERNAL_ROLE)
		const includeExternalRoles = includeRoleTypes.includes(RoleTypes.EXTERNAL_ROLE)

		const [rolesOptions, setRolesOptions] = useState<UfinetSelectOptionValue[]>()
		const isGroupedSelector = includeRoleTypes.length > 1

		const [loadingOptions, setLoadingOptions] = useState(false)

		const mapRoleToSelectOption = (role: Role): IUfinetSelectOption => ({
			label: role.name,
			value: role.id!.toString(),
			type: role.type,
		})

		const getRoles = useCallback<() => Promise<RoleFindResponse[]>>(() => {
			setLoadingOptions(true)

			return includeInternalRoles && includeExternalRoles
				? _rolesService.findAll()
				: includeInternalRoles && !includeExternalRoles
				? _rolesService.findInternal()
				: !includeInternalRoles && includeExternalRoles
				? _rolesService.findExternal()
				: new Promise<RoleFindResponse[]>((resolve) => resolve([]))
		}, [_rolesService, includeExternalRoles, includeInternalRoles])

		const onRolesFetched = useCallback<(roles: Role[]) => void>(
			(roles) => {
				const rolesListedOptions: IUfinetSelectOption[] = roles.map(mapRoleToSelectOption)
				const rolesGroupedOptions: IUfinetGroupedOption[] = Object.values(RoleTypes).map((roleType) => ({
					label: translate(roleTypeMappings.get(roleType)!) || roleType,
					options: roles.filter((role) => role.type === roleType).map(mapRoleToSelectOption),
				}))

				setRolesOptions(isGroupedSelector ? rolesGroupedOptions : rolesListedOptions)
			},
			[isGroupedSelector, translate]
		)

		const onRolesFailedToFetch = console.error

		const runFinally = () => {
			setLoadingOptions(false)
		}

		useAsync(
			{
				asyncFn: getRoles,
				onSuccess: onRolesFetched,
				onFailure: onRolesFailedToFetch,
				runFinally: runFinally,
			},
			[]
		)

		useEffect(() => {
			if (!props.value) selectRef.current?.clearSelect()
		}, [props.value])

		useImperativeHandle(
			ref,
			() =>
				({
					clearSelect: selectRef.current?.clearSelect,
					isEmpty: selectRef.current?.isEmpty,
				} as RoleSelectHandle),
			[]
		)

		return (
			<UfinetSelect
				ref={selectRef}
				{...props}
				labelTitle={props.noLabel ? '' : props.labelTitle || translate('ROLES')}
				tooltipTitle={props.tooltipTitle || translate('ROLES.TOOLTIP')}
				options={rolesOptions}
				value={!props.isMulti ? (props.value as IUfinetSelectOption)?.label && props.value : props.value || []}
				requiredIcon={props.requiredIcon ? props.requiredIcon : false}
				isClearable={!unclearable}
				placeholder={translate('ROLES')}
				isLoadingOptions={loadingOptions}
			/>
		)
	}
)

export { RoleSelect }
