import { UserOrganizationalTypeFindResponse } from 'modules/organization/user/type/application/find/dto/UserOrganizationalTypeFindResponse'
import { UserOrganizationalType } from 'modules/organization/user/type/domain/UserOrganizationalType'
import { rest } from 'msw'

export const mockUserOrganizationalTypes: UserOrganizationalTypeFindResponse[] = [
	{ id: 1, value: UserOrganizationalType.DIRECTOR },
	{ id: 2, value: UserOrganizationalType.KAM },
	{ id: 3, value: UserOrganizationalType.SUPERVISOR },
	{ id: 4, value: UserOrganizationalType.GENERAL },
]

export const userOrganizationalTypeHandlers = [
	rest.get(/.*organization\/user\/types/, (req, res, ctx) => {
		return res(ctx.status(200), ctx.json(mockUserOrganizationalTypes))
	}),
]
