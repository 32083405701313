import { FC, useEffect, useState } from 'react'
import { setRolePermission } from '../../../store/role/rolePermissionSlice'
import { useDispatch } from 'react-redux'

type Props = {
	rowData: any
	options: any
}
export const EditRoleButton: FC<Props> = ({ rowData, options }) => {
	const [status, setStatus] = useState<boolean>(false)
	const dispatch = useDispatch()

	const changeStatus = () => {
		const optionsArray = options.field.split('.')
		const rolePermissionByModule = JSON.parse(JSON.stringify(rowData))
		rolePermissionByModule[optionsArray[0]][optionsArray[1]] = !status
		dispatch(setRolePermission(rolePermissionByModule))
		setStatus(!status)
	}
	useEffect(() => {
		const optionsArray = options.field.split('.')
		setStatus(
			options && options.field && rowData && optionsArray.length === 2
				? rowData[optionsArray[0]][optionsArray[1]]
				: false
		)
	}, [options, rowData])
	return status ? (
		<span className="badge badge-primary" onClick={changeStatus}>
			Habilitado
		</span>
	) : (
		<span className="badge badge-light" onClick={changeStatus}>
			Deshabilitado
		</span>
	)
}
