import { CompanyFindResponse } from 'modules/company/application/find/dto/CompanyFindResponse'
import { rest } from 'msw'

export const mockCompanies: CompanyFindResponse[] = []

export const companyHandlers = [
	rest.get('*api/company', (req, res, ctx) => {
		return res(ctx.status(200), ctx.json(mockCompanies))
	}),
]
