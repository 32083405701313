import { faKey, faListDots, faSitemap } from '@fortawesome/free-solid-svg-icons'
import { faBuilding } from '@fortawesome/free-solid-svg-icons/faBuilding'
import { faIdCardClip } from '@fortawesome/free-solid-svg-icons/faIdCardClip'
import { faUserGroup } from '@fortawesome/free-solid-svg-icons/faUserGroup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC, useContext, useState } from 'react'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import { AsideMenuItem, AsideMenuItemWithSub, IMenuItem } from 'ufinet-web-components'
import { AuthContext, Authority, useInternalUser } from 'ufinet-web-functions'
import Wso2Logo from '../../../media/img/wso2-vector-logo.svg'
import {
	PATH_APPS,
	PATH_COMPANIES,
	PATH_ORGANIZATION,
	PATH_ROLES,
	PATH_USERS,
	PATH_WSO2_APPS,
	routerNameMap,
} from '../../../routing/protected/PrivateRoutes'

enum AsideMenu {
	MANAGEMENT,
	APPLICATIONS,
	NONE,
}

const AsideMenuMain: FC = () => {
	const intl = useIntl()
	const location = useLocation()

	const [active, setActive] = useState(AsideMenu.NONE)
	const isManagementActive = () => active === AsideMenu.MANAGEMENT
	const isApplicationsActive = () => active === AsideMenu.APPLICATIONS
	const activeMenu = (menu: AsideMenu) => () => setActive(menu)

	const internalUser = useInternalUser()
	const { userData } = useContext(AuthContext)

	const roles = userData?.authorities || []
	const securityPermissions = Authority.getSecurityConfigPermissions(roles)
	const applicationPermissions = Authority.getApplicationConfigPermissions(roles)

	const menuManagementItems: IMenuItem[] = [
		{
			to: PATH_USERS,
			title: routerNameMap.get(PATH_USERS),
			icon: faUserGroup,
			hidden: !securityPermissions.canRead,
		},
		{
			to: PATH_COMPANIES,
			title: routerNameMap.get(PATH_COMPANIES),
			icon: faBuilding,
			hidden: !internalUser || !securityPermissions.canRead,
		},
		{
			to: PATH_ORGANIZATION,
			title: routerNameMap.get(PATH_ORGANIZATION),
			icon: faSitemap,
			hidden: !internalUser || !securityPermissions.canRead,
		},
	]

	const menuApplicationItems: IMenuItem[] = [
		{
			to: PATH_APPS,
			title: routerNameMap.get(PATH_APPS),
			icon: faListDots,
			hidden: !internalUser || !applicationPermissions.canRead,
		},
		{
			to: PATH_WSO2_APPS,
			title: routerNameMap.get(PATH_WSO2_APPS),
			icon: Wso2Logo,
			hidden: !internalUser || !applicationPermissions.canRead,
		},
		{
			to: PATH_ROLES,
			title: routerNameMap.get(PATH_ROLES),
			icon: faIdCardClip,
			hidden: !internalUser || !applicationPermissions.canRead || !securityPermissions.canRead,
		},
	]

	return (
		<>
			{securityPermissions.canRead && (
				<AsideMenuItemWithSub
					icon={<FontAwesomeIcon icon={faKey} className="fs-2x" />}
					title={intl.formatMessage({ id: 'MENU.MANAGEMENT' })}
					active={isManagementActive()}
				>
					{menuManagementItems
						.filter((item) => !item.hidden)
						.map((item, _index) => (
							<AsideMenuItem
								routerLocation={location}
								key={item.to}
								to={item.to}
								icon={item.icon}
								title={intl.formatMessage({ id: item.title })}
								onClick={activeMenu(AsideMenu.MANAGEMENT)}
							/>
						))}
				</AsideMenuItemWithSub>
			)}
			{internalUser && applicationPermissions.canRead && (
				<AsideMenuItemWithSub
					icon={<FontAwesomeIcon icon={faListDots} className="fs-2x" />}
					title={intl.formatMessage({ id: 'MENU.APPLICATIONS' })}
					active={isApplicationsActive()}
				>
					{menuApplicationItems
						.filter((item) => !item.hidden)
						.map((item, _index) => (
							<AsideMenuItem
								routerLocation={location}
								key={item.to}
								to={item.to}
								icon={item.icon}
								title={intl.formatMessage({ id: item.title })}
								onClick={activeMenu(AsideMenu.APPLICATIONS)}
							/>
						))}
				</AsideMenuItemWithSub>
			)}
		</>
	)
}

export { AsideMenuMain }
