import { companyService } from 'modules/company/application/companiesService'
import { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { ISelectBaseInterface, IUfinetSelectOption, UfinetSelect, UfinetSelectHandle } from 'ufinet-web-components'
import { AuthContext } from 'ufinet-web-functions'

export type CompanySelectHandle = UfinetSelectHandle & {}

export type CompanySelectProps = ISelectBaseInterface & {
	unclearable?: boolean
}

const CompanySelect = forwardRef<CompanySelectHandle, CompanySelectProps>(({ unclearable, ...props }, ref) => {
	const selectRef = useRef<UfinetSelectHandle>(null)

	const intl = useIntl()
	const authData = useContext(AuthContext)

	const _companyService = useMemo(() => companyService(authData, intl), [authData, intl])

	const [companiesOptions, setCompaniesOptions] = useState<IUfinetSelectOption[]>()

	const fillCompanies = useCallback(() => {
		_companyService
			.findCompanies()
			.then((companies) => {
				return setCompaniesOptions(
					companies.map((company) => ({
						label: company.name,
						value: company.id.toString(),
					}))
				)
			})
			.catch(console.error)
	}, [_companyService])

	useEffect(() => {
		fillCompanies()
	}, [fillCompanies])

	useEffect(() => {
		if (!props.value) selectRef.current?.clearSelect()
	}, [props.value])

	useImperativeHandle(
		ref,
		() =>
			({
				clearSelect: selectRef.current?.clearSelect,
				isEmpty: selectRef.current?.isEmpty,
			} as CompanySelectHandle),
		[]
	)

	return (
		<UfinetSelect
			ref={selectRef}
			{...props}
			labelTitle={props.noLabel ? '' : intl.formatMessage({ id: 'COMPANY' })}
			tooltipTitle={intl.formatMessage({ id: 'COMPANY.TOOLTIP' })}
			options={companiesOptions}
			value={!props.isMulti ? (props.value as IUfinetSelectOption)?.label && props.value : props.value || []}
			requiredIcon={props.requiredIcon ? props.requiredIcon : false}
			className={props.className ? props.className : ''}
			isMulti={props.isMulti ? props.isMulti : false}
			error={props.error}
			isClearable={!unclearable}
			onChange={props.onChange}
			isDisabled={props.isDisabled ? props.isDisabled : false}
			placeholder=""
		/>
	)
})

export { CompanySelect }
