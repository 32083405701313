import { FormikHelpers, FormikProps, useFormik } from 'formik'
import { internalMailRegExp, useTranslator } from 'ufinet-web-functions'
import * as Yup from 'yup'
import { UserOrganizationFormData } from './useUserOrganizationForm'

export type UserOrganizationFormParameters = Partial<UserOrganizationFormData>
type UserOrganizationFormSchema = {
	[key in keyof UserOrganizationFormParameters]: Yup.SchemaOf<unknown>
}

type HookInput = {
	onSubmit: (
		formData: UserOrganizationFormParameters,
		formikHelpers?: FormikHelpers<UserOrganizationFormParameters>
	) => Promise<void>
	onReset?: (
		formData: UserOrganizationFormParameters,
		formikHelpers?: FormikHelpers<UserOrganizationFormParameters>
	) => void
}
type HookOutput = {
	formik: FormikProps<UserOrganizationFormParameters>
	initialValues: UserOrganizationFormParameters
}

function useUserOrganizationFormValidation({ onSubmit, onReset }: HookInput): HookOutput {
	const translate = useTranslator()

	const initialValues: UserOrganizationFormParameters = {}
	const validationSchema = Yup.object().shape<UserOrganizationFormSchema>(
		{
			userType: Yup.number().required(translate('ERROR.REQUIRED')),
			userEmail: Yup.string()
				.required(translate('ERROR.REQUIRED'))
				.trim()
				.email(translate('ERROR.MAIL.INVALID'))
				.matches(internalMailRegExp, translate('ERROR.MAIL.INVALID.INTERNAL')),
		},
		[]
	)

	const formik = useFormik<UserOrganizationFormParameters>({
		initialValues,
		validationSchema,
		validateOnChange: false,
		onSubmit,
		onReset,
	})

	return { formik, initialValues }
}

export { useUserOrganizationFormValidation }
