import { emptyUfinetSelectOption, IUfinetSelectOption } from 'ufinet-web-components'

export type ApplicationWso2FormData = {
	applicationName: string
	corporateGroup: IUfinetSelectOption
	active: boolean
}

export const initialWso2AppForm: ApplicationWso2FormData = {
	applicationName: '',
	corporateGroup: emptyUfinetSelectOption,
	active: false,
}
