import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RolePermissionsByModule } from 'modules/role/domain/permission/RolePermissionsByModule'
import { emptyRoleDetail, RoleDetail } from 'modules/role/domain/Role'
import { RoleTypes } from 'modules/role/domain/types/RoleTypes'

type IRolePermissionsState = {
	selectedRolePermission: RoleDetail
}

const initialState: IRolePermissionsState = {
	selectedRolePermission: emptyRoleDetail,
}

export const rolePermissionSlice = createSlice({
	name: 'rolePermission',
	initialState,
	reducers: {
		setSelectedRolePermission: (state: IRolePermissionsState, action: PayloadAction<RoleDetail>) => {
			state.selectedRolePermission = { ...action.payload }
		},
		setSelectedRolePermissionName: (state: IRolePermissionsState, action: PayloadAction<string>) => {
			return {
				...state,
				selectedRolePermission: {
					...state.selectedRolePermission,
					name: action.payload,
				},
			}
		},
		setSelectedRolePermissionType: (state: IRolePermissionsState, action: PayloadAction<RoleTypes>) => {
			return {
				...state,
				selectedRolePermission: {
					...state.selectedRolePermission,
					type: action.payload,
				},
			}
		},
		setRolePermission: (state: IRolePermissionsState, action: PayloadAction<RolePermissionsByModule>) => {
			state.selectedRolePermission.permissions.forEach((application) =>
				application.permissions.forEach((module) => {
					if (module.moduleId === action.payload.moduleId) {
						module.permissions = { ...action.payload.permissions }
					}
				})
			)
		},
		resetSelectedRolePermission: () => {
			return initialState
		},
	},
})

export const {
	setSelectedRolePermission,
	setSelectedRolePermissionName,
	setSelectedRolePermissionType,
	setRolePermission,
	resetSelectedRolePermission,
} = rolePermissionSlice.actions

export default rolePermissionSlice.reducer
