import { IntlShape } from 'react-intl'
import { defaultOptionsFetch, FetchAPI, FetchOptions, IAuthData, successNotifications } from 'ufinet-web-functions'
import { InternalUser } from '../domain/InternalUser'
import { InternalUserFindResponse } from './find/dto/InternalUserFindResponse'
import { InternalUserUpdateRequest } from './update/dto/InternalUserUpdateRequest'

const url = `${process.env.REACT_APP_URL_BACK_PORTAL}api/user/internal`

export const internalUserService = (authData: IAuthData, intl: IntlShape) => {
	const fetchOptions: FetchOptions = {
		...defaultOptionsFetch,
		authenticationData: authData,
	}

	return {
		findAll: async (options?: FetchOptions): Promise<InternalUserFindResponse[]> =>
			await FetchAPI.get(url, { ...fetchOptions, ...options }),

		find: async (id: number, options?: FetchOptions): Promise<InternalUserFindResponse> =>
			await FetchAPI.get(`${url}/${id}`, { ...fetchOptions, ...options }),

		create: async (criteria: InternalUser, options?: FetchOptions): Promise<void> =>
			await FetchAPI.post(url, criteria, {
				...fetchOptions,
				notification: successNotifications.createRegistry(intl, criteria.email),
				...options,
			}),

		update: async (id: number, criteria: InternalUserUpdateRequest, options?: FetchOptions): Promise<void> =>
			await FetchAPI.put(`${url}/${id}`, criteria, {
				...fetchOptions,
				notification: successNotifications.updateRegistry(intl, criteria.email),
				...options,
			}),

		delete: async (id: number, options?: FetchOptions): Promise<void> =>
			await FetchAPI.delete(`${url}/${id}`, {
				...fetchOptions,
				notification: successNotifications.deleteById(intl, {
					id,
					section: intl.formatMessage({ id: 'SECTION.USERS' }).toLowerCase(),
				}),
				...options,
			}),
	}
}
