import { IntlShape } from 'react-intl'
import {
	defaultOptionsFetch,
	FetchAPI,
	FetchOptions,
	IAuthData,
	IMultipleDeleteRequest,
	successNotifications,
} from 'ufinet-web-functions'
import { ApplicationWso2FindResponse } from './find/dto/ApplicationWso2FindResponse'
import { ApplicationWso2SaveRequest } from './save/dto/ApplicationWso2SaveRequest'
import { ApplicationWso2UpdateRequest } from './update/dto/ApplicationWso2UpdateRequest'

const url = `${process.env.REACT_APP_URL_BACK_PORTAL}api/application_wso2`

export const applicationWso2Service = (authData: IAuthData, intl: IntlShape) => {
	const fetchOptions: FetchOptions = {
		...defaultOptionsFetch,
		authenticationData: authData,
	}

	return {
		findAll: async (options?: FetchOptions): Promise<ApplicationWso2FindResponse[]> =>
			await FetchAPI.get(url, { ...fetchOptions, ...options }),
		findById: async (id: string, options?: FetchOptions): Promise<ApplicationWso2FindResponse> =>
			await FetchAPI.get(`${url}/${id}`, { ...fetchOptions, ...options }),
		create: async (requestData: ApplicationWso2SaveRequest, options?: FetchOptions): Promise<void> =>
			await FetchAPI.post(url, requestData, {
				...fetchOptions,
				notification: successNotifications.createRegistry(intl, requestData.applicationName),
				...options,
			}),
		update: async (id: string, requestData: ApplicationWso2UpdateRequest, options?: FetchOptions): Promise<void> =>
			await FetchAPI.put(`${url}/${id}`, requestData, {
				...fetchOptions,
				notification: successNotifications.updateRegistry(intl, requestData.applicationName),
				...options,
			}),
		deleteOne: async (id: number, options?: FetchOptions): Promise<void> =>
			await FetchAPI.delete(`${url}/${id}`, {
				...fetchOptions,
				notification: successNotifications.deleteById(intl, {
					id,
					section: intl.formatMessage({ id: 'SECTION.APPS.WSO2' }).toLowerCase(),
				}),
				...options,
			}),
		deleteMany: async (ids: IMultipleDeleteRequest<number>, options?: FetchOptions): Promise<void> =>
			await FetchAPI.deleteWithBody(`${url}`, ids, {
				...fetchOptions,
				notification: successNotifications.deleteByIdsList(intl, {
					section: intl.formatMessage({ id: 'SECTION.APPS.WSO2' }).toLowerCase(),
				}),
				...options,
			}),
	}
}
