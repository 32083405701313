import { useFormik } from 'formik'
import { applicationService } from 'modules/apps/application/applicationService'

import { FC, useCallback, useContext, useRef } from 'react'
import { useIntl } from 'react-intl'
import { UfinetActionButton, UfinetActionButtonHandle, UfinetInput } from 'ufinet-web-components'
import { AuthContext, onFormikTextChanges } from 'ufinet-web-functions'
import * as Yup from 'yup'

type Props = {
	search: Function
	hideModal: Function
}
export const NewAppModalBody: FC<Props> = ({ hideModal, search }) => {
	const intl = useIntl()
	const actionButtonRef = useRef<UfinetActionButtonHandle>(null)

	const authData = useContext(AuthContext)
	const _applicationService = applicationService(authData, intl)

	const dataFormSchema = Yup.object().shape({
		name: Yup.string().required(intl.formatMessage({ id: 'ERROR.REQUIRED' })),
		description: Yup.string().required(intl.formatMessage({ id: 'ERROR.REQUIRED' })),
		securityName: Yup.string().required(intl.formatMessage({ id: 'ERROR.REQUIRED' })),
	})
	const sendData = (values: any) => {
		actionButtonRef.current?.changeActionStatus()
		_applicationService
			.create({
				name: values.name,
				description: values.description,
				securityName: values.securityName,
			})
			.then(() => {
				search()
				hideModal()
				return values
			})
			.catch(console.error)
			.finally(() => actionButtonRef.current?.changeActionStatus())
	}
	const formik = useFormik({
		initialValues: {
			name: '',
			description: '',
			securityName: '',
		},
		validationSchema: dataFormSchema,
		onSubmit: sendData,
		validateOnChange: false,
		validateOnBlur: false,
	})

	const onTextChange = useCallback(onFormikTextChanges, [])

	return (
		<form onSubmit={formik.handleSubmit} className="container h-100 d-flex flex-column justify-content-center">
			<div className="row pb-4">
				<div className="row pt-4">
					<UfinetInput
						requiredIcon
						error={formik.errors.name}
						type="text"
						labelTitle={intl.formatMessage({ id: 'APPLICATION.NAME' })}
						tooltipTitle={intl.formatMessage({ id: 'APPLICATION.NAME.TOOLTIP' })}
						className="col-6"
						onChange={onTextChange(formik, 'name')}
						value={formik.values.name}
						solid={false}
					/>
					<UfinetInput
						requiredIcon
						error={formik.errors.securityName}
						type="text"
						labelTitle={intl.formatMessage({ id: 'APPLICATION.CODE' })}
						tooltipTitle={intl.formatMessage({ id: 'APPLICATION.CODE.TOOLTIP' })}
						className="col-6"
						onChange={onTextChange(formik, 'securityName')}
						value={formik.values.securityName}
						solid={false}
					/>
				</div>
				<div className="row pt-4">
					<UfinetInput
						requiredIcon
						error={formik.errors.description}
						type="text"
						labelTitle={intl.formatMessage({ id: 'APPLICATION.DESCRIPTION' })}
						tooltipTitle={intl.formatMessage({ id: 'APPLICATION.DESCRIPTION.TOOLTIP' })}
						className="col-12"
						onChange={onTextChange(formik, 'description')}
						value={formik.values.description}
						solid={false}
					/>
				</div>
			</div>
			<UfinetActionButton ref={actionButtonRef} className="mt-10" content={intl.formatMessage({ id: 'SAVE' })} />
		</form>
	)
}
