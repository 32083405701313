import { AsideDefault } from 'components/layout/aside/AsideDefault'
import { AsideMobile } from 'components/layout/aside/AsideMobile'
import { AppsPage } from 'pages/apps/AppsPage'
import { ModulesPage } from 'pages/apps/modules-section/ModulesPage'
import { Wso2AppsPage } from 'pages/apps/wso2/Wso2AppsPage'
import { CompaniesPage } from 'pages/companies/CompaniesPage'
import { UsersOrganizationPage } from 'pages/organization/UsersOrganizationPage'
import { RolesPage } from 'pages/roles/RolesPage'
import { UsersPage } from 'pages/user/UsersPage'
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { AuthRoute, MasterLayout, WelcomePage } from 'ufinet-web-components'
import { Authority, UserTypesEnum } from 'ufinet-web-functions'

export const PATH_HOME = ''
export const PATH_USERS = 'users'
export const PATH_ORGANIZATION = 'organization'
export const PATH_COMPANIES = 'companies'
export const PATH_APPS = 'applications'
export const PATH_WSO2_APPS = 'wso2'
export const PATH_ROLES = 'roles'
const MODULES = 'modules'
export const PATH_MODULES = `${PATH_APPS}/${MODULES}`

// routerMap: traduccion de cada elemento del la url que se quiere mostrar en el menu de forma individual
// si tenemos un PATH_X="ejemplo/ejemplo2", en nuestro routerMap metemos una clave para ejemplo y otra para ejemplo2
export const routerNameMap = new Map<string, string>([
	[PATH_HOME, 'PATH_HOME'],
	[PATH_USERS, 'PATH_USERS'],
	[PATH_ORGANIZATION, 'PATH_USERS_ORGANIZATION'],
	[PATH_COMPANIES, 'PATH_COMPANIES'],
	[PATH_ROLES, 'PATH_ROLES'],
	[PATH_APPS, 'PATH_APPS'],
	[MODULES, 'PATH_MODULES'],
	[PATH_WSO2_APPS, 'PATH_WSO2_APPS'],
])

export const routerLinkMap = new Map<string, string>([
	[PATH_HOME, `/${PATH_HOME}`],
	[PATH_USERS, `/${PATH_USERS}`],
	[PATH_ORGANIZATION, `/${PATH_ORGANIZATION}`],
	[PATH_COMPANIES, `/${PATH_COMPANIES}`],
	[PATH_APPS, `/${PATH_APPS}`],
	[PATH_ROLES, `/${PATH_ROLES}`],
	[PATH_WSO2_APPS, `/${PATH_WSO2_APPS}`],
])

export const routePermissionMap = new Map<string, Authority[]>([
	[PATH_USERS, [Authority.securityConfigModuleRead]],
	[PATH_ORGANIZATION, [Authority.securityConfigModuleRead]],
	[PATH_COMPANIES, [Authority.securityConfigModuleRead]],
	[PATH_ROLES, [Authority.securityConfigModuleRead, Authority.applicationsConfigModuleRead]],
	[PATH_APPS, [Authority.applicationsConfigModuleRead]],
	[PATH_MODULES, [Authority.applicationsConfigModuleRead]],
	[PATH_WSO2_APPS, [Authority.applicationsConfigModuleRead]],
])

const PrivateRoutes: FC = () => {
	const intl = useIntl()
	const location = useLocation()

	return (
		<Routes>
			<Route
				element={
					<MasterLayout
						routerLocation={location}
						asideDefault={<AsideDefault />}
						routerNameMap={routerNameMap}
						asideMobile={<AsideMobile />}
					/>
				}
			>
				<Route path={PATH_HOME} element={<WelcomePage appName={intl.formatMessage({ id: 'PORTAL.NAME' })} />} />

				<Route
					path={PATH_USERS}
					element={
						<AuthRoute authorizedRoles={routePermissionMap.get(PATH_USERS)!}>
							<UsersPage />
						</AuthRoute>
					}
				/>

				<Route
					path={PATH_COMPANIES}
					element={
						<AuthRoute
							authorizedRoles={routePermissionMap.get(PATH_COMPANIES)!}
							authorizedUsers={[UserTypesEnum.INTERNAL_USER]}
						>
							<CompaniesPage />
						</AuthRoute>
					}
				/>

				<Route
					path={PATH_ORGANIZATION}
					element={
						<AuthRoute
							authorizedRoles={routePermissionMap.get(PATH_ORGANIZATION)!}
							authorizedUsers={[UserTypesEnum.INTERNAL_USER]}
						>
							<UsersOrganizationPage />
						</AuthRoute>
					}
				/>

				<Route
					path={PATH_ROLES}
					element={
						<AuthRoute
							authorizedRoles={routePermissionMap.get(PATH_ROLES)!}
							authorizedUsers={[UserTypesEnum.INTERNAL_USER]}
						>
							<RolesPage />
						</AuthRoute>
					}
				/>

				<Route
					path={PATH_APPS}
					element={
						<AuthRoute
							authorizedRoles={routePermissionMap.get(PATH_APPS)!}
							authorizedUsers={[UserTypesEnum.INTERNAL_USER]}
						>
							<AppsPage />
						</AuthRoute>
					}
				/>

				<Route
					path={`${PATH_MODULES}/:appId/:appName`}
					element={
						<AuthRoute
							authorizedRoles={routePermissionMap.get(PATH_MODULES)!}
							authorizedUsers={[UserTypesEnum.INTERNAL_USER]}
						>
							<ModulesPage />
						</AuthRoute>
					}
				/>

				<Route
					path={PATH_WSO2_APPS}
					element={
						<AuthRoute
							authorizedRoles={routePermissionMap.get(PATH_WSO2_APPS)!}
							authorizedUsers={[UserTypesEnum.INTERNAL_USER]}
						>
							<Wso2AppsPage />
						</AuthRoute>
					}
				/>

				{/* Default route path */}
				<Route path="*" element={<Navigate to={PATH_HOME} replace />} />
			</Route>
		</Routes>
	)
}

export { PrivateRoutes }
