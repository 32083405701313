export const UserPropertyMap = new Map<string, string>([
	['userId', 'userId'],
	['email', 'email'],
	['fullName', 'fullName'],
	['userType', 'userType'],
	['roles', 'roles'],
	['lastUpdatedBy', 'lastUpdatedBy'],
	['lastUpdatedAt', 'lastUpdatedAt'],
])

export const UserGlobalFilterFields = ['email', 'fullName']
