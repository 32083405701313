import { rest } from 'msw'
import { ICountry } from 'ufinet-web-functions'

export const mockCountries: ICountry[] = []

export const countryHandlers = [
	rest.get('*api/countries', (req, res, ctx) => {
		return res(ctx.status(200), ctx.json(mockCountries))
	}),
]
