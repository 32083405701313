import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import { RoleSelect } from 'components/selects/RoleSelect'
import { useFormik } from 'formik'
import { RoleTypes } from 'modules/role/domain/types/RoleTypes'
import { internalUserModelFromResponse } from 'modules/user/internal/application/internalUserModelFromResponse'
import { internalUserService } from 'modules/user/internal/application/internalUserService'
import { initialInternalUser, InternalUser } from 'modules/user/internal/domain/InternalUser'
import { FC, useContext, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { IUfinetSelectOption, Loading, UfinetButton, UfinetInput } from 'ufinet-web-components'
import {
	AuthContext,
	compareStringIgnoreCase,
	FetchOptions,
	internalMailRegExp,
	onFormikTrimField,
	UserTypesEnum,
} from 'ufinet-web-functions'
import * as Yup from 'yup'
import { optionToRole } from '../mappers/roleToOptionMapper'

type InternalUserWithType = InternalUser & { type: UserTypesEnum }

type InternalUserModalProps = {
	userId?: number
	afterSubmit?: () => void
	onUserSubmitted?: (newUser: InternalUser) => void
	onSubmitError?: (error: any) => any
}

const InternalUserModal: FC<InternalUserModalProps> = ({
	userId,
	afterSubmit = () => {},
	onUserSubmitted = () => {},
	onSubmitError = () => {},
}) => {
	const intl = useIntl()
	const authData = useContext(AuthContext)

	const [loading, setLoading] = useState<boolean>(!!userId)
	const [canEditRoles, setCanEditRoles] = useState<boolean>(true)

	const _internalUsersService = useMemo(() => internalUserService(authData, intl), [authData, intl])

	const fillInWithExistingUser = (userId: number): void => {
		_internalUsersService
			.find(userId)
			.then((user) => {
				formik.setValues({
					...internalUserModelFromResponse(user),
					type: UserTypesEnum.INTERNAL_USER,
				} as InternalUserWithType)
				setCanEditRoles(!compareStringIgnoreCase(user.email, authData.userData?.username))
				return user
			})
			.catch(console.error)
			.finally(() => setLoading(false))
	}

	useEffect(() => {
		if (!userId) return
		fillInWithExistingUser(userId)
	}, [])

	const dataFormSchema = Yup.object().shape({
		type: Yup.string().oneOf([UserTypesEnum.INTERNAL_USER]).required(),
		email: Yup.string()
			.required(intl.formatMessage({ id: 'ERROR.REQUIRED' }))
			.email(intl.formatMessage({ id: 'ERROR.MAIL.INVALID' }))
			.matches(internalMailRegExp, intl.formatMessage({ id: 'ERROR.MAIL.INVALID.INTERNAL' })),
		roles: Yup.array().notRequired(),
	})

	const formik = useFormik<InternalUserWithType>({
		initialValues: { ...initialInternalUser, type: UserTypesEnum.INTERNAL_USER },
		validationSchema: dataFormSchema,
		onSubmit: (user) => {
			submitUser(user)
			afterSubmit()
		},
		validateOnChange: false,
		validateOnBlur: false,
		enableReinitialize: true,
		onReset: (values, form) => {
			form.setValues({
				...initialInternalUser,
				type: UserTypesEnum.INTERNAL_USER,
				roles: canEditRoles ? initialInternalUser.roles : formik.values.roles,
			})
		},
	})

	const submitUser = (formUser: InternalUser): void => {
		const reqOptions: FetchOptions = { notifyError: true, notifyWithMessage: false }
		const res = userId
			? _internalUsersService.update(userId, formUser, reqOptions)
			: _internalUsersService.create(formUser, reqOptions)

		res
			.then(() => {
				onUserSubmitted(formUser)
				return formUser
			})
			.catch((err) => {
				onSubmitError(err)
			})
	}

	return (
		<form
			onSubmit={formik.handleSubmit}
			className={`d-flex flex-column justify-content-center p-0 m-0 ${loading ? 'form-disabled' : ''}`}
		>
			<div className="row">
				<div className="row">
					<div className="row">
						{/* EMAIL */}
						<UfinetInput
							{...formik.getFieldProps}
							requiredIcon
							className="col-6"
							error={formik.errors.email}
							type="text"
							labelTitle={intl.formatMessage({ id: 'EMAIL' })}
							tooltipTitle={intl.formatMessage({
								id: 'EMAIL.TOOLTIP',
							})}
							onChange={(e) => formik.setFieldValue('email', e.target?.value || initialInternalUser.email)}
							onBlur={() => onFormikTrimField(formik, 'email', '')}
							value={formik.values.email}
							solid={false}
							autofocus
						/>
					</div>
				</div>
				<div className="row pt-4">
					{/* ROLES */}
					<RoleSelect
						requiredIcon={false}
						className="col-6"
						error={formik.errors.roles}
						isMulti
						isDisabled={!canEditRoles}
						value={formik.values.roles.map(
							(role) =>
								({
									label: role.name,
									value: role.id.toString(),
									type: role.type,
								} as IUfinetSelectOption)
						)}
						includeRoleTypes={[RoleTypes.INTERNAL_ROLE, RoleTypes.EXTERNAL_ROLE]}
						tooltipWarning={
							formik.values.roles.some((role) => role.type === RoleTypes.EXTERNAL_ROLE)
								? intl.formatMessage({ id: 'ROLES.CROSSED' })
								: !canEditRoles
								? intl.formatMessage({ id: 'ROLES.EDIT.ERROR' })
								: undefined
						}
						onChange={
							canEditRoles
								? (roles) => {
										const selectedRoles = (roles as IUfinetSelectOption[]).map(optionToRole)
										formik.setFieldValue('roles', selectedRoles)
								  }
								: undefined
						}
					/>
				</div>

				<div className="row">
					<UfinetButton
						className="mt-5 ms-3 p-5 w-auto"
						content={intl.formatMessage({
							id: userId ? 'USER.UPDATE' : 'USER.ADD',
						})}
						icon={faPlus}
						onClick={() => formik.handleSubmit()}
						isDisabled={false}
					/>
					<UfinetButton
						secondaryButton
						className="mt-5 ms-3 p-5 w-auto"
						content={intl.formatMessage({ id: 'CLEAR' })}
						icon={faTrash}
						onClick={formik.handleReset}
						isDisabled={false}
					/>
				</div>
			</div>
			<button className="d-none" type="submit" onClick={() => formik.handleSubmit()} />

			{loading && <Loading />}
		</form>
	)
}

export default InternalUserModal
