import { rest } from 'msw'
import { ICorporateGroup } from 'ufinet-web-functions'

export const mockCorporateGroups: ICorporateGroup[] = []

export const corporateGroupHandlers = [
	rest.get('*api/corporate-group', (req, res, ctx) => {
		return res(ctx.status(200), ctx.json(mockCorporateGroups))
	}),
]
