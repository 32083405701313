import { useQuery } from '@tanstack/react-query'
import { UserOrganizationalTypeRepository } from '../../domain/repository/UserOrganizationalTypeRepository'
import { UserOrganizationalTypeFindResponse } from './dto/UserOrganizationalTypeFindResponse'

export const useUserOrganizationalTypeFindAllQuery = (
	userOrganizationalTypeRepository: UserOrganizationalTypeRepository,
	{
		enabled = true,
		onSuccess,
		onError,
	}: {
		enabled?: boolean
		onSuccess?: (userOrganizationalTypes: UserOrganizationalTypeFindResponse[]) => void
		onError?: (error: unknown) => void
	} = {}
) => {
	return useQuery({
		queryKey: ['user-organizational-types-find-all'],
		queryFn: userOrganizationalTypeRepository.findAll,
		enabled,
		onSuccess,
		onError,
		staleTime: 120000,
	})
}
