import { rest } from 'msw'
import { IClientResponse } from 'ufinet-web-functions'

export const mockClients: IClientResponse[] = []

export const clientHandlers = [
	rest.get('*api/client/find', (req, res, ctx) => {
		return res(ctx.status(200), ctx.json(mockClients))
	}),
	rest.post('*api/client/find', (req, res, ctx) => {
		return res(ctx.status(200), ctx.json(mockClients))
	}),
]
