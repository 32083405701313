import { rest } from 'msw'
import { clientHandlers } from 'tests/modules/client/application/clientHandlers'
import { companyHandlers } from 'tests/modules/company/application/companyHandlers'
import { corporateGroupHandlers } from 'tests/modules/corporate-group/application/corporateGroupHandlers'
import { countryHandlers } from 'tests/modules/country/application/countryHandlers'
import { userOrganizationalTypeHandlers } from 'tests/modules/organization/user/type/application/userOrganizationalTypeHandlers'
import { roleHandlers } from 'tests/modules/role/application/roleHandlers'
import { svgHandlers } from 'tests/modules/svg/application/svgHandlers'
import { userHandlers } from 'tests/modules/user/application/userHandlers'

const baseHandlers = [
	rest.get(/^(?!.*api\/).*$/, (req, res, ctx) => {
		return res(ctx.status(200), ctx.json([]))
	}),
]
export const handlers = [
	...svgHandlers,
	...countryHandlers,
	...corporateGroupHandlers,
	...clientHandlers,
	...companyHandlers,
	...userHandlers,
	...roleHandlers,
	...userOrganizationalTypeHandlers,
	...baseHandlers,
]
