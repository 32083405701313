import { IntlShape } from 'react-intl'
import {
	defaultOptionsFetch,
	FetchAPI,
	FetchOptions,
	IAuthData,
	IMultipleDeleteRequest,
	successNotifications,
} from 'ufinet-web-functions'
import { ApplicationFindResponse } from './find/dto/ApplicationFindResponse'
import { ApplicationSaveRequest } from './save/dto/ApplicationSaveRequest'
import { ApplicationUpdateRequest } from './update/dto/ApplicationUpdateRequest'

const url = `${process.env.REACT_APP_URL_BACK_PORTAL}api/application`

export const applicationService = (authData: IAuthData, intl: IntlShape) => {
	const fetchOptions: FetchOptions = {
		...defaultOptionsFetch,
		authenticationData: authData,
	}

	return {
		findApps: async (): Promise<ApplicationFindResponse[]> => await FetchAPI.get(url, fetchOptions),
		findAppById: async (id: number): Promise<ApplicationFindResponse> =>
			await FetchAPI.get(`${url}/${id}`, fetchOptions),
		create: async (request: ApplicationSaveRequest): Promise<any> =>
			await FetchAPI.post(`${url}`, request, {
				...fetchOptions,
				notification: successNotifications.createRegistry(intl, request.name),
			}),
		updateApp: async (id: number, request: ApplicationUpdateRequest): Promise<any> =>
			await FetchAPI.put(`${url}/${id}`, request, {
				...fetchOptions,
				notification: successNotifications.updateRegistry(intl, request.name),
			}),
		deleteApp: async (id: number): Promise<any> =>
			await FetchAPI.delete(`${url}/${id}`, {
				...fetchOptions,
				notification: successNotifications.deleteById(intl, {
					id,
					section: intl.formatMessage({ id: 'SECTION.APPS' }).toLowerCase(),
				}),
			}),
		deleteByIdsList: async (ids: IMultipleDeleteRequest<number>): Promise<any> =>
			await FetchAPI.deleteWithBody(`${url}`, ids, {
				...fetchOptions,
				notification: successNotifications.deleteByIdsList(intl, {
					section: intl.formatMessage({ id: 'SECTION.APPS' }).toLowerCase(),
				}),
			}),
	}
}
